<template>
    <v-container fluid class="my-10">
      <v-row>
  
        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCrear = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col md="6" justify="center">
                  <v-text-field v-model="nombreIn" counter="0" label="Nombre" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
                <v-col md="6" justify="center">
                  <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="requestItems" text large :disabled="!showSolicitarReporte">Solicitar Reporte
              </v-btn>
              <v-btn color="danger" text @click="showDialogCrear = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Reporte:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este reporte sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        
        <v-col cols="12">
          <h3>Control de CFDI de nómina
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/control-de-cfdi-de-nomina" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="11">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="11" class="paddingCard">
          <v-card >
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col  cols="12" md="2" class="px-2 mx-md-10">
                    <v-select :items="itemsYears" v-model="periodoModel" label="Año de Fecha de Pago"></v-select>
                  </v-col>
                  <v-col  cols="12" md="2" class="px-2 mx-md-10">
                    <v-text-field v-model="rfcSearch" counter="0" label="RFC" class="my-1" maxlength="13"></v-text-field>
                  </v-col>
                  <v-col  cols="12" md="2" class="px-2 mx-md-10">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" white large v-on="on" v-on:click="showDialogCrear = true"
                          >Solicitar Reporte</v-btn>
                      </template>
                      <span>Solicitar reporte sin limite documentos</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" md="11">
                    <v-card>
                      <v-toolbar dark color="primary">
                       
                        <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="ObtenerReportes" icon v-on="on">
                              <v-icon>mdi-cached</v-icon>
                            </v-btn>
                          </template>
                          <span>Refrescar Tabla</span>
                        </v-tooltip>
                      </v-toolbar>
  
                      <v-row>
                        <v-col cols="12">
                        </v-col>
                        <v-col cols="12">
                          <v-simple-table height="600" v-if="itemsGrandes.length > 0"  fixed-header dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th>Nombre</th>  
                                  <th>Descripción</th>
                                  <th>Fecha Solicitud</th>
                                  <th>RFC</th>
                                  <th>Año</th>
                                  <th>Mensaje</th>
                                  <th>Disponible</th>
                                  <th>Resultado</th>
  
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                                  <td>
                                    {{ item.name }}
                                  </td>
                                  <td>
                                    {{ item.description }}
                                  </td>
                                  <td>
                                    {{ item.requestDate }}
                                  </td>
                                  <td>
                                    {{ JSON.parse(item.commands).rfc }}
                                  </td>
                                  <td>
                                    {{ JSON.parse(item.commands).year }}
                                  </td>
                                  <td>
                                    {{ item.message }}
                                  </td>
                                  <td>
                                    {{ item.ready ? "Si" : "No" }}
                                  </td>
                                  <td>
                                    {{
                                        item.ready
                                          ? item.result
                                            ? "Exitoso"
                                            : "Fracasado"
                                          : "Procesando"
                                    }}
                                  </td>
                                  <td class="pa-0 md-0">
                                    <v-tooltip left v-if="item.ready && item.result">
                                      <template v-slot:activator="{ on }">
                                        <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                                          <v-icon>mdi-download</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Descargar Reporte</span>
                                    </v-tooltip>
                                  </td>
                                  <td class="pa-0 md-0">
                                    <v-tooltip left v-if="item.ready">
                                      <template v-slot:activator="{ on }">
                                        <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Eliminar Reporte</span>
                                    </v-tooltip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import helpers from "../../scripts/helpers.js";
  import service from "../../scripts/services.js";
  import { JSONToCSV } from "../../scripts/helpers.js";
  
  export default {
    name: "ReporteControlNomina",
  
    data: () => ({
      showDialogEliminarReport: false,
      showDialogCrear: false,
      itemsGrandes: [],
      descripcionIn: "",
      itemDesc: "",
      modal: false,
      
      
      
      
      search: "",
      items: [],
      landscape: false,
      title: "",
      rfcSearch: "",
      itemsYears: [],
      periodoModel: new Date().getFullYear(),
      nombreIn: ""
    }),
    created() { 
        this.fillItems();
        this.ObtenerReportes(); 
    },
  
    methods: {

        fillItems() {
      var i;
      for (i = this.periodoModel - 5; i <= this.periodoModel; i++)
        this.itemsYears.push(i);
    },
  
      requestItems() {
        this.showDialogCrear = false;
  
        var jsonData = {
          reportType: 'REPORT-CONTROL-CFDI-NOMINA',
          name: this.nombreIn,
          description: this.descripcionIn,
          commands: JSON.stringify({year: this.periodoModel, rfc: this.rfcSearch}),
          token: this.$store.state.token,
        };
        service
          .api()
          .RequestOnDemandReport(jsonData)
          .then((response) => {
            if (response.result) {

              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
              this.$store.commit("setAlertMessage", "Reporte Solicitado Correctamente");

            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
            this.descripcionIn = "";
          });
  
  
      },
      ObtenerReportes() {
        var jsonData = {
          token: this.$store.state.token,
          reportType: 'REPORT-CONTROL-CFDI-NOMINA'
        };
  
        service
          .api()
          .RetrieveOnDemandReports(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.itemsGrandes = JSON.parse(response.payload);
              
            } else {
              this.itemsGrandes = [];
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      },
      ConfirmarEliminarReporte(item) {
        this.eliminarId = item.id;
        this.itemDesc = item.description;
        this.showDialogEliminarReport = true;
      },
      EliminarReporte() {
        var jsonData = {
          id: this.eliminarId,
          token: this.$store.state.token,
          reportType: 'REPORT-CONTROL-CFDI-NOMINA'
        };
        this.showDialogEliminarReport = false;
        service
          .api()
          .DeleteOnDemandReport(jsonData)
          .then((response) => {
            if (response.result) {
              this.alert_okIn = true;
              this.okMessageIn = "Reporte Eliminado Correctamente";
              setTimeout(() => {
                this.alert_okIn = false;
                this.okMessageIn = "";
              }, 10000);
              this.ObtenerReportes();
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      },
      DescargarReporte(itemId) {
        var jsonData = {
          id: itemId,
          token: this.$store.state.token          
        };
  
        service
          .api()
          .RetrieveOnDemandReport(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.retrieveReporte(response.payload);
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      },
      retrieveReporte(ruta) {
        this.$store.commit("setOverlay", true);
        if (navigator.userAgent.indexOf("Firefox") > -1) {
          window.open(ruta, "_blank");
        } else {
          helpers.openNewBackgroundTab(ruta);
        }
        setTimeout(() => {
          this.$store.commit("setOverlay", false);
        }, 1000);
      },
  
    },
    computed: {
        showCreatedButton(){
            return this.rfcSearch.length >= 12  && this.rfcSearch.length <= 13;
        },
        showSolicitarReporte(){
            return this.descripcionIn.length > 3;
        }
    },
  };
  </script>
  <style scoped>
  .altura {
    height: 40px;
  }
  
  .negro {
    font-weight: bold;
  }
  
  .ancho {
    width: 35px;
  }
  
  .anchoP {
    width: 100px;
  }
  
  .normal {
    font-weight: regular;
  }
  
  .tamPagina {
    font-weight: bold;
    font-size: 20px;
  }

  .paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}
  </style>
  