import store from "../store/store";

let currencyFormat = function(value) {
  return new Intl.NumberFormat("es-MX", {
    style: "decimal",
    currency: "MXN",
    currencyDisplay: "symbol",
    useGrouping: true,
    minimumFractionDigits: 2,
  }).format(Number(value));
};


let formatTax = function(
  amount,
  decimalCount = 6,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch {
    return "0.00";
  }
};

let formatMoney = function(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch {
    return "0.00";
  }
};

let formatCommas = function(
  amount,
  decimalCount = 0,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch {
    return "0";
  }
};

let convertToCSV = function(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";
      line += array[i][index];
    }
    str += line.replace(/null/g, "") + "\r\n";
  }
  return str;
};
let exportCSVFile = function(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  var jsonObject = JSON.stringify(items);
  var csv = this.convertToCSV(jsonObject);
  var exportedFilenmae = fileTitle + ".csv" || "export.csv";
  var blob = new Blob([csv], {
    type: "text/csv;charset=utf-8;",
  });
  if (navigator.msSaveBlob) {
    //ie10
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", exportedFilenmae);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

let exportCSVFileV1 = function(headers, items, fileTitle, datos) {
  if (headers) {
    items.unshift(headers);
  }

  if (datos) {
    for (var l in datos) {
      var datosObj = Object.assign({}, headers);
      let i = 0;
      for (var k in datosObj) {
        if (i === 0) datosObj[k] = l + ":" + datos[l];
        else datosObj[k] = "";
        // keys.push(k);
        i++;
      }

      items.unshift(datosObj);
    }
  }
  var jsonObject = JSON.stringify(items);
  var csv = this.convertToCSV(jsonObject);
  var exportedFilenmae = fileTitle + ".csv" || "export.csv";
  var blob = new Blob([csv], {
    type: "text/csv;charset=utf-8;",
  });
  if (navigator.msSaveBlob) {
    //ie10
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", exportedFilenmae);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

let dateNow = function() {
  let now = new Date();
  let year = "" + now.getFullYear();
  let month = "" + (now.getMonth() + 1);
  if (month.length == 1) {
    month = "0" + month;
  }
  let day = "" + now.getDate();
  if (day.length == 1) {
    day = "0" + day;
  }
  let hour = "" + now.getHours();
  if (hour.length == 1) {
    hour = "0" + hour;
  }
  let minute = "" + now.getMinutes();
  if (minute.length == 1) {
    minute = "0" + minute;
  }
  let second = "" + now.getSeconds();
  if (second.length == 1) {
    second = "0" + second;
  }
  return (
    year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
  );
};

let dateNowShort = function() {
  let now = new Date();
  let year = "" + now.getFullYear();
  let month = "" + (now.getMonth() + 1);
  if (month.length == 1) {
    month = "0" + month;
  }
  let day = "" + now.getDate();
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "-" + month + "-" + day;
};

let dateNowCSV = function() {
  let now = new Date();
  let year = "" + now.getFullYear();
  let month = "" + (now.getMonth() + 1);
  if (month.length == 1) {
    month = "0" + month;
  }
  let day = "" + now.getDate();
  if (day.length == 1) {
    day = "0" + day;
  }
  let hour = "" + now.getHours();
  if (hour.length == 1) {
    hour = "0" + hour;
  }
  let minute = "" + now.getMinutes();
  if (minute.length == 1) {
    minute = "0" + minute;
  }
  let second = "" + now.getSeconds();
  if (second.length == 1) {
    second = "0" + second;
  }
  return (
    year +
    "_" +
    month +
    "_" +
    day +
    "_hora_" +
    hour +
    "_" +
    minute +
    "_" +
    second
  );
};

let IsNotNull = function(valor) {
  return !(valor === undefined || valor === null || valor === "");
};

let ConvertToEmpty = function(valor) {
  if (valor === undefined) return " ";
  if (valor === null) return " ";
  if (valor === "") return " ";

  return valor.toString().replace(/,/g, "");
};

let FechaActualReal = function() {
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  return localISOTime.substring(0, 19);
};

let FechaActual = function() {
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  return localISOTime.substring(0, 19).replace("T", " ");
};
let FechaActualSinHoras = function() {
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  return localISOTime.substring(0, 10);
};
let ConvertToNoAplica = function(valor) {
  if (valor === undefined) return "No Aplica";
  if (valor === null) return "No Aplica";
  if (valor === "") return "No Aplica";
  if (valor === "Seleccionar Todos") return "No Aplica";

  return valor.toString().replace(/,/g, "");
};

let ConvertToZero = function(valor) {
  if (valor === undefined) return 0;
  if (valor === null) return 0;
  if (valor === "") return 0;

  return valor;
};

let Base64Encode = function(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
};
let Base64Decode = function(str) {
  return decodeURIComponent(escape(window.atob(str)));
};
let Base64ToArrayBuffer = function(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};
let openNewBackgroundTab = function(url) {
  var a = document.createElement("a");
  a.href = url;
  var evt = document.createEvent("MouseEvents");
  evt.initMouseEvent(
    "click",
    true,
    true,
    window,
    0,
    0,
    0,
    0,
    0,
    true,
    false,
    false,
    false,
    0,
    null
  );
  document.body.appendChild(a);
  a.dispatchEvent(evt);
};
let ToPDF = function(base64doc, pdfname) {
  var arraybuf = Base64ToArrayBuffer(base64doc);
  var blob = new Blob([arraybuf]);
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = pdfname;
  link.click();
  if (navigator.userAgent.indexOf("Firefox") != -1) {
    window.open("data:application/pdf;base64, " + base64doc);
  } else {
    window.open("data:application/pdf;base64, " + base64doc).close();
  }
};

let ToXML = function(base64doc, xmlname) {
  let xmlContent = Base64Decode(base64doc);
  let blob = new Blob([xmlContent], { type: "text/xml" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = xmlname;
  link.click();
  if (navigator.userAgent.indexOf("Firefox") != -1) {
    window.open(link);
  } else {
    window.open(link).close();
  }
  window.URL.revokeObjectURL(link);
};

let LastDayMonth = function(y, m) {
  return m === 2
    ? y & 3 || (!(y % 25) && y & 15)
      ? 28
      : 29
    : 30 + ((m + (m >> 3)) & 1);
};

let docTypeTranslate = function(docType) {
  let result = "Ingreso";
  switch (docType) {
    case "E":
      result = "Egreso";
      break;
    case "I":
      result = "Ingreso";
      break;
  }
  return result;
};

let RegimenesConcat = [
  {
    codigo: "601",
    name: "601 - General de Ley Personas Morales",
  },
  {
    codigo: "603",
    name: "603 - Personas Morales con Fines no Lucrativos",
  },
  {
    codigo: "605",
    name: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
  },
  {
    codigo: "606",
    name: "606 - Arrendamiento",
  },
  {
    codigo: "608",
    name: "608 - Demás ingresos",
  },
  {
    codigo: "609",
    name: "609 - Consolidación",
  },
  {
    codigo: "610",
    name:
      "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    codigo: "611",
    name: "611 - Ingresos por Dividendos (socios y accionistas)",
  },
  {
    codigo: "612",
    name:
      "612 - Personas Físicas con Actividades Empresariales y Profesionales",
  },
  {
    codigo: "614",
    name: "614 - Ingresos por intereses",
  },
  {
    codigo: "616",
    name: "616 - Sin obligaciones fiscales",
  },
  {
    codigo: "620",
    name:
      "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  {
    codigo: "621",
    name: "621 - Incorporación Fiscal",
  },
  {
    codigo: "622",
    name: "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  {
    codigo: "623",
    name: "623 - Opcional para Grupos de Sociedades",
  },
  {
    codigo: "624",
    name: "624 - Coordinados",
  },
  {
    codigo: "626",
    name: "626 - Régimen Simplificado de Confianza",
  },

  {
    codigo: "628",
    name: "628 - Hidrocarburos",
  },
  {
    codigo: "607",
    name: "607 - Régimen de Enajenación o Adquisición de Bienes",
  },
  {
    codigo: "629",
    name:
      "629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  },
  {
    codigo: "630",
    name: "630 - Enajenación de acciones en bolsa de valores",
  },
  {
    codigo: "615",
    name: "615 - Régimen de los ingresos por obtención de premios",
  },
  {
    codigo: "625",
    name:
      "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
];

let Regimenes = [
  {
    codigo: "601",
    name: "General de Ley Personas Morales",
  },
  {
    codigo: "603",
    name: "Personas Morales con Fines no Lucrativos",
  },
  {
    codigo: "605",
    name: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
  },
  {
    codigo: "606",
    name: "Arrendamiento",
  },
  {
    codigo: "608",
    name: "Demás ingresos",
  },
  {
    codigo: "609",
    name: "Consolidación",
  },
  {
    codigo: "610",
    name:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    codigo: "611",
    name: "Ingresos por Dividendos (socios y accionistas)",
  },
  {
    codigo: "612",
    name: "Personas Físicas con Actividades Empresariales y Profesionales",
  },
  {
    codigo: "614",
    name: "Ingresos por intereses",
  },
  {
    codigo: "616",
    name: "Sin obligaciones fiscales",
  },
  {
    codigo: "620",
    name:
      "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  {
    codigo: "621",
    name: "Incorporación Fiscal",
  },
  {
    codigo: "622",
    name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  {
    codigo: "623",
    name: "Opcional para Grupos de Sociedades",
  },
  {
    codigo: "624",
    name: "Coordinados",
  },
  {
    codigo: "626",
    name: "626 - Régimen Simplificado de Confianza",
  },
  {
    codigo: "628",
    name: "Hidrocarburos",
  },
  {
    codigo: "607",
    name: "Régimen de Enajenación o Adquisición de Bienes",
  },
  {
    codigo: "629",
    name:
      "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  },
  {
    codigo: "630",
    name: "Enajenación de acciones en bolsa de valores",
  },
  {
    codigo: "615",
    name: "Régimen de los ingresos por obtención de premios",
  },
  {
    codigo: "625",
    name:
      "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
];

let RegimenFiscal = function(codigo) {
  let result = "";
  if (codigo === undefined) return "";
  if (codigo === null) return "";
  if (codigo.length === 0) return "";
  const regimenes = Regimenes;
  try {
    result = regimenes.filter((f) => f.codigo === codigo)[0].name;
  } catch (error) {
    result = codigo;
  }
  return result;
};

let Impuesto = function(codigo) {
  let result = "";
  if (codigo === undefined) return "";
  if (codigo === null) return "";
  if (codigo.length === 0) return "";
  const impuestos = [
    {
      codigo: "001",
      name: "ISR",
    },
    {
      codigo: "002",
      name: "IVA",
    },
    {
      codigo: "003",
      name: "IEPS",
    },
  ];
  try {
    result = impuestos.filter((f) => f.codigo === codigo)[0];
  } catch (error) {
    result = codigo;
  }
  return result.codigo + "-" + result.name;
};

let UsosCFDIsConcat = [
  {
    codigo: "G01",
    name: "G01 - Adquisición de mercancías",
  },
  {
    codigo: "G02",
    name: "G02 - Devoluciones, descuentos o bonificaciones",
  },
  {
    codigo: "G03",
    name: "G03 - Gastos en general",
  },
  {
    codigo: "I01",
    name: "I01 - Construcciones",
  },
  {
    codigo: "I02",
    name: "I02 - Mobilario y equipo de oficina por inversiones",
  },
  {
    codigo: "I03",
    name: "I03 - Equipo de transporte",
  },
  {
    codigo: "I04",
    name: "I04 - Equipo de computo y accesorios",
  },
  {
    codigo: "I05",
    name: "I05 - Dados, troqueles, moldes, matrices y herramental",
  },
  {
    codigo: "I06",
    name: "I06 - Comunicaciones telefónicas",
  },
  {
    codigo: "I07",
    name: "I07 - Comunicaciones satelitales",
  },
  {
    codigo: "I08",
    name: "I08 - Otra maquinaria y equipo",
  },
  {
    codigo: "D01",
    name: "D01 - Honorarios médicos, dentales y gastos hospitalarios.",
  },
  {
    codigo: "D02",
    name: "D02 - Gastos médicos por incapacidad o discapacidad",
  },
  {
    codigo: "D03",
    name: "D03 - Gastos funerales.",
  },
  {
    codigo: "D04",
    name: "D04 - Donativos.",
  },
  {
    codigo: "D05",
    name:
      "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
  },
  {
    codigo: "D06",
    name: "D06 - Aportaciones voluntarias al SAR.",
  },
  {
    codigo: "D07",
    name: "D07 - Primas por seguros de gastos médicos.",
  },
  {
    codigo: "D08",
    name: "D08 - Gastos de transportación escolar obligatoria.",
  },
  {
    codigo: "D09",
    name:
      "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
  },
  {
    codigo: "D10",
    name: "D10 - Pagos por servicios educativos (colegiaturas)",
  },
  {
    codigo: "CP01",
    name: "CP01 - Pagos",
  },
  {
    codigo: "CN01",
    name: "CN01 - Nómina",
  },
  {
    codigo: "S01",
    name: "S01 - Sin Efectos Fiscales",
  },

  {
    codigo: "P01",
    name: "P01 - Por definir",
  },
];

let UsosCFDIs = [
  {
    codigo: "G01",
    name: "Adquisición de mercancias",
  },
  {
    codigo: "G02",
    name: "Devoluciones, descuentos o bonificaciones",
  },
  {
    codigo: "G03",
    name: "Gastos en general",
  },
  {
    codigo: "I01",
    name: "Construcciones",
  },
  {
    codigo: "I02",
    name: "Mobilario y equipo de oficina por inversiones",
  },
  {
    codigo: "I03",
    name: "Equipo de transporte",
  },
  {
    codigo: "I04",
    name: "Equipo de computo y accesorios",
  },
  {
    codigo: "I05",
    name: "Dados, troqueles, moldes, matrices y herramental",
  },
  {
    codigo: "I06",
    name: "Comunicaciones telefónicas",
  },
  {
    codigo: "I07",
    name: "Comunicaciones satelitales",
  },
  {
    codigo: "I08",
    name: "Otra maquinaria y equipo",
  },
  {
    codigo: "D01",
    name: "Honorarios médicos, dentales y gastos hospitalarios.",
  },
  {
    codigo: "D02",
    name: "Gastos médicos por incapacidad o discapacidad",
  },
  {
    codigo: "D03",
    name: "Gastos funerales.",
  },
  {
    codigo: "D04",
    name: "Donativos.",
  },
  {
    codigo: "D05",
    name:
      "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
  },
  {
    codigo: "D06",
    name: "Aportaciones voluntarias al SAR.",
  },
  {
    codigo: "D07",
    name: "Primas por seguros de gastos médicos.",
  },
  {
    codigo: "D08",
    name: "Gastos de transportación escolar obligatoria.",
  },
  {
    codigo: "D09",
    name:
      "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
  },
  {
    codigo: "D10",
    name: "Pagos por servicios educativos (colegiaturas)",
  },
  {
    codigo: "P01",
    name: "Por definir",
  },
];

let UsoCFDI = function(codigo) {
  let result = "";
  if (codigo === undefined) return "";
  if (codigo === null) return "";
  if (codigo.length === 0) return "";
  const usos = UsosCFDIs;
  try {
    result = usos.filter((f) => f.codigo === codigo)[0].name;
  } catch (error) {
    result = codigo;
  }
  return result;
};
let MonedasConcat = [
  {
    codigo: "XXX",
    name:
      "XXX - Los códigos asignados para las transacciones en que intervenga ninguna moneda",
  },
  { codigo: "MXN", name: "MXN - Peso Mexicano" },
  { codigo: "USD", name: "USD - Dolar americano" },
  { codigo: "EUR", name: "EUR - Euro" },
  { codigo: "GBP", name: "GBP - Libra Esterlina" },
  { codigo: "JPY", name: "JPY - Yen" },
  { codigo: "AED", name: "AED - Dirham de EAU" },
  { codigo: "AFN", name: "AFN - Afghani" },
  { codigo: "ALL", name: "ALL - Lek" },
  { codigo: "AMD", name: "AMD - Dram armenio" },
  { codigo: "ANG", name: "ANG - Florín antillano neerlandés" },
  { codigo: "AOA", name: "AOA - Kwanza" },
  { codigo: "ARS", name: "ARS - Peso Argentino" },
  { codigo: "AUD", name: "AUD - Dólar Australiano" },
  { codigo: "AWG", name: "AWG - Aruba Florin" },
  { codigo: "AZN", name: "AZN - Azerbaijanian Manat" },
  { codigo: "BAM", name: "BAM - Convertibles marca" },
  { codigo: "BBD", name: "BBD - Dólar de Barbados" },
  { codigo: "BDT", name: "BDT - Taka" },
  { codigo: "BGN", name: "BGN - Lev búlgaro" },
  { codigo: "BHD", name: "BHD - Dinar de Bahrein" },
  { codigo: "BIF", name: "BIF - Burundi Franc" },
  { codigo: "BMD", name: "BMD - Dólar de Bermudas" },
  { codigo: "BND", name: "BND - Dólar de Brunei" },
  { codigo: "BOB", name: "BOB - Boliviano" },
  { codigo: "BOV", name: "BOV - Mvdol" },
  { codigo: "BRL", name: "BRL - Real brasileño" },
  { codigo: "BSD", name: "BSD - Dólar de las Bahamas" },
  { codigo: "BTN", name: "BTN - Ngultrum" },
  { codigo: "BWP", name: "BWP - Pula" },
  { codigo: "BYR", name: "BYR - Rublo bielorruso" },
  { codigo: "BZD", name: "BZD - Dólar de Belice" },
  { codigo: "CAD", name: "CAD - Dolar Canadiense" },
  { codigo: "CDF", name: "CDF - Franco congoleño" },
  { codigo: "CHE", name: "CHE - WIR Euro" },
  { codigo: "CHF", name: "CHF - Franco Suizo" },
  { codigo: "CHW", name: "CHW - Franc WIR" },
  { codigo: "CLF", name: "CLF - Unidad de Fomento" },
  { codigo: "CLP", name: "CLP - Peso chileno" },
  { codigo: "CNY", name: "CNY - Yuan Renminbi" },
  { codigo: "COP", name: "COP - Peso Colombiano" },
  { codigo: "COU", name: "COU - Unidad de Valor real" },
  { codigo: "CRC", name: "CRC - Colón costarricense" },
  { codigo: "CUC", name: "CUC - Peso Convertible" },
  { codigo: "CUP", name: "CUP - Peso Cubano" },
  { codigo: "CVE", name: "CVE - Cabo Verde Escudo" },
  { codigo: "CZK", name: "CZK - Corona checa" },
  { codigo: "DJF", name: "DJF - Franco de Djibouti" },
  { codigo: "DKK", name: "DKK - Corona danesa" },
  { codigo: "DOP", name: "DOP - Peso Dominicano" },
  { codigo: "DZD", name: "DZD - Dinar argelino" },
  { codigo: "EGP", name: "EGP - Libra egipcia" },
  { codigo: "ERN", name: "ERN - Nakfa" },
  { codigo: "ETB", name: "ETB - Birr etíope" },
  { codigo: "FJD", name: "FJD - Dólar de Fiji" },
  { codigo: "FKP", name: "FKP - Libra malvinense" },
  { codigo: "GEL", name: "GEL - Lari" },
  { codigo: "GHS", name: "GHS - Cedi de Ghana" },
  { codigo: "GIP", name: "GIP - Libra de Gibraltar" },
  { codigo: "GMD", name: "GMD - Dalasi" },
  { codigo: "GNF", name: "GNF - Franco guineano" },
  { codigo: "GTQ", name: "GTQ - Quetzal" },
  { codigo: "GYD", name: "GYD - Dólar guyanés" },
  { codigo: "HKD", name: "HKD - Dolar De Hong Kong" },
  { codigo: "HNL", name: "HNL - Lempira" },
  { codigo: "HRK", name: "HRK - Kuna" },
  { codigo: "HTG", name: "HTG - Gourde" },
  { codigo: "HUF", name: "HUF - Florín" },
  { codigo: "IDR", name: "IDR - Rupia" },
  { codigo: "ILS", name: "ILS - Nuevo Shekel Israelí" },
  { codigo: "INR", name: "INR - Rupia india" },
  { codigo: "IQD", name: "IQD - Dinar iraquí" },
  { codigo: "IRR", name: "IRR - Rial iraní" },
  { codigo: "ISK", name: "ISK - Corona islandesa" },
  { codigo: "JMD", name: "JMD - Dólar Jamaiquino" },
  { codigo: "JOD", name: "JOD - Dinar jordano" },
  { codigo: "KES", name: "KES - Chelín keniano" },
  { codigo: "KGS", name: "KGS - Som" },
  { codigo: "KHR", name: "KHR - Riel" },
  { codigo: "KMF", name: "KMF - Franco Comoro" },
  { codigo: "KPW", name: "KPW - Corea del Norte ganó" },
  { codigo: "KRW", name: "KRW - Won" },
  { codigo: "KWD", name: "KWD - Dinar kuwaití" },
  { codigo: "KYD", name: "KYD - Dólar de las Islas Caimán" },
  { codigo: "KZT", name: "KZT - Tenge" },
  { codigo: "LAK", name: "LAK - Kip" },
  { codigo: "LBP", name: "LBP - Libra libanesa" },
  { codigo: "LKR", name: "LKR - Rupia de Sri Lanka" },
  { codigo: "LRD", name: "LRD - Dólar liberiano" },
  { codigo: "LSL", name: "LSL - Loti" },
  { codigo: "LYD", name: "LYD - Dinar libio" },
  { codigo: "MAD", name: "MAD - Dirham marroquí" },
  { codigo: "MDL", name: "MDL - Leu moldavo" },
  { codigo: "MGA", name: "MGA - Ariary malgache" },
  { codigo: "MKD", name: "MKD - Denar" },
  { codigo: "MMK", name: "MMK - Kyat" },
  { codigo: "MNT", name: "MNT - Tugrik" },
  { codigo: "MOP", name: "MOP - Pataca" },
  { codigo: "MRO", name: "MRO - Ouguiya" },
  { codigo: "MUR", name: "MUR - Rupia de Mauricio" },
  { codigo: "MVR", name: "MVR - Rupia" },
  { codigo: "MWK", name: "MWK - Kwacha" },
  { codigo: "MXV", name: "MXV - México Unidad de Inversión (UDI)" },
  { codigo: "MYR", name: "MYR - Ringgit malayo" },
  { codigo: "MZN", name: "MZN - Mozambique Metical" },
  { codigo: "NAD", name: "NAD - Dólar de Namibia" },
  { codigo: "NGN", name: "NGN - Naira" },
  { codigo: "NIO", name: "NIO - Córdoba Oro" },
  { codigo: "NOK", name: "NOK - Corona noruega" },
  { codigo: "NPR", name: "NPR - Rupia nepalí" },
  { codigo: "NZD", name: "NZD - Dólar de Nueva Zelanda" },
  { codigo: "OMR", name: "OMR - Rial omaní" },
  { codigo: "PAB", name: "PAB - Balboa" },
  { codigo: "PEN", name: "PEN - Nuevo Sol" },
  { codigo: "PGK", name: "PGK - Kina" },
  { codigo: "PHP", name: "PHP - Peso filipino" },
  { codigo: "PKR", name: "PKR - Rupia de Pakistán" },
  { codigo: "PLN", name: "PLN - Zloty" },
  { codigo: "PYG", name: "PYG - Guaraní" },
  { codigo: "QAR", name: "QAR - Qatar Rial" },
  { codigo: "RON", name: "RON - Leu rumano" },
  { codigo: "RSD", name: "RSD - Dinar serbio" },
  { codigo: "RUB", name: "RUB - Rublo ruso" },
  { codigo: "RWF", name: "RWF - Franco ruandés" },
  { codigo: "SAR", name: "SAR - Riyal saudí" },
  { codigo: "SBD", name: "SBD - Dólar de las Islas Salomón" },
  { codigo: "SCR", name: "SCR - Rupia de Seychelles" },
  { codigo: "SDG", name: "SDG - Libra sudanesa" },
  { codigo: "SEK", name: "SEK - Corona sueca" },
  { codigo: "SGD", name: "SGD - Dolar De Singapur" },
  { codigo: "SHP", name: "SHP - Libra de Santa Helena" },
  { codigo: "SLL", name: "SLL - Leona" },
  { codigo: "SOS", name: "SOS - Chelín somalí" },
  { codigo: "SRD", name: "SRD - Dólar de Suriname" },
  { codigo: "SSP", name: "SSP - Libra sudanesa Sur" },
  { codigo: "STD", name: "STD - Dobra" },
  { codigo: "SVC", name: "SVC - Colon El Salvador" },
  { codigo: "SYP", name: "SYP - Libra Siria" },
  { codigo: "SZL", name: "SZL - Lilangeni" },
  { codigo: "THB", name: "THB - Baht" },
  { codigo: "TJS", name: "TJS - Somoni" },
  { codigo: "TMT", name: "TMT - Turkmenistán nuevo manat" },
  { codigo: "TND", name: "TND - Dinar tunecino" },
  { codigo: "TOP", name: "TOP - Pa anga" },
  { codigo: "TRY", name: "TRY - Lira turca" },
  { codigo: "TTD", name: "TTD - Dólar de Trinidad y Tobago" },
  { codigo: "TWD", name: "TWD - Nuevo dólar de Taiwán" },
  { codigo: "TZS", name: "TZS - Shilling tanzano" },
  { codigo: "UAH", name: "UAH - Hryvnia" },
  { codigo: "UGX", name: "UGX - Shilling de Uganda" },
  { codigo: "USN", name: "USN - Dólar estadounidense (día siguiente)" },
  {
    codigo: "UYI",
    name: "UYI - Peso Uruguay en Unidades Indexadas (URUIURUI)",
  },
  { codigo: "UYU", name: "UYU - Peso Uruguayo" },
  { codigo: "UZS", name: "UZS - Uzbekistán Sum" },
  { codigo: "VEF", name: "VEF - Bolívar" },
  { codigo: "VND", name: "VND - Dong" },
  { codigo: "VUV", name: "VUV - Vatu" },
  { codigo: "WST", name: "WST - Tala" },
  { codigo: "XAF", name: "XAF - Franco CFA BEAC" },
  { codigo: "XAG", name: "XAG - Plata" },
  { codigo: "XAU", name: "XAU - Oro" },
  {
    codigo: "XBA",
    name: "XBA - Unidad de Mercados de Bonos Unidad Europea Composite (EURCO)",
  },
  {
    codigo: "XBB",
    name: "XBB - Unidad Monetaria de Bonos de Mercados Unidad Europea (UEM-6)",
  },
  {
    codigo: "XBC",
    name: "XBC - Mercados de Bonos Unidad Europea unidad de cuenta a 9 (UCE-9)",
  },
  {
    codigo: "XBD",
    name:
      "XBD - Mercados de Bonos Unidad Europea unidad de cuenta a 17 (UCE-17)",
  },
  { codigo: "XCD", name: "XCD - Dólar del Caribe Oriental" },
  { codigo: "XDR", name: "XDR - DEG (Derechos Especiales de Giro)" },
  { codigo: "XOF", name: "XOF - Franco CFA BCEAO" },
  { codigo: "XPD", name: "XPD - Paladio" },
  { codigo: "XPF", name: "XPF - Franco CFP" },
  { codigo: "XPT", name: "XPT - Platino" },
  { codigo: "XSU", name: "XSU - Sucre" },
  {
    codigo: "XTS",
    name: "XTS - Códigos reservados específicamente para propósitos de prueba",
  },
  { codigo: "XUA", name: "XUA - Unidad ADB de Cuenta" },
  { codigo: "YER", name: "YER - Rial yemení" },
  { codigo: "ZAR", name: "ZAR - Rand" },
  { codigo: "ZMW", name: "ZMW - Kwacha zambiano" },
  { codigo: "ZWL", name: "ZWL - Zimbabwe Dólar" },
];

let Monedas = [
  {
    codigo: "XXX",
    name:
      "XXX - Los códigos asignados para las transacciones en que intervenga ninguna moneda",
  },
  { codigo: "MXN", name: "Peso Mexicano" },
  { codigo: "USD", name: "Dolar americano" },
  { codigo: "EUR", name: "Euro" },
  { codigo: "GBP", name: "Libra Esterlina" },
  { codigo: "JPY", name: "Yen" },
  { codigo: "AED", name: "Dirham de EAU" },
  { codigo: "AFN", name: "Afghani" },
  { codigo: "ALL", name: "Lek" },
  { codigo: "AMD", name: "Dram armenio" },
  { codigo: "ANG", name: "Florín antillano neerlandés" },
  { codigo: "AOA", name: "Kwanza" },
  { codigo: "ARS", name: "Peso Argentino" },
  { codigo: "AUD", name: "Dólar Australiano" },
  { codigo: "AWG", name: "Aruba Florin" },
  { codigo: "AZN", name: "Azerbaijanian Manat" },
  { codigo: "BAM", name: "Convertibles marca" },
  { codigo: "BBD", name: "Dólar de Barbados" },
  { codigo: "BDT", name: "Taka" },
  { codigo: "BGN", name: "Lev búlgaro" },
  { codigo: "BHD", name: "Dinar de Bahrein" },
  { codigo: "BIF", name: "Burundi Franc" },
  { codigo: "BMD", name: "Dólar de Bermudas" },
  { codigo: "BND", name: "Dólar de Brunei" },
  { codigo: "BOB", name: "Boliviano" },
  { codigo: "BOV", name: "Mvdol" },
  { codigo: "BRL", name: "Real brasileño" },
  { codigo: "BSD", name: "Dólar de las Bahamas" },
  { codigo: "BTN", name: "Ngultrum" },
  { codigo: "BWP", name: "Pula" },
  { codigo: "BYR", name: "Rublo bielorruso" },
  { codigo: "BZD", name: "Dólar de Belice" },
  { codigo: "CAD", name: "Dolar Canadiense" },
  { codigo: "CDF", name: "Franco congoleño" },
  { codigo: "CHE", name: "WIR Euro" },
  { codigo: "CHF", name: "Franco Suizo" },
  { codigo: "CHW", name: "Franc WIR" },
  { codigo: "CLF", name: "Unidad de Fomento" },
  { codigo: "CLP", name: "Peso chileno" },
  { codigo: "CNY", name: "Yuan Renminbi" },
  { codigo: "COP", name: "Peso Colombiano" },
  { codigo: "COU", name: "Unidad de Valor real" },
  { codigo: "CRC", name: "Colón costarricense" },
  { codigo: "CUC", name: "Peso Convertible" },
  { codigo: "CUP", name: "Peso Cubano" },
  { codigo: "CVE", name: "Cabo Verde Escudo" },
  { codigo: "CZK", name: "Corona checa" },
  { codigo: "DJF", name: "Franco de Djibouti" },
  { codigo: "DKK", name: "Corona danesa" },
  { codigo: "DOP", name: "Peso Dominicano" },
  { codigo: "DZD", name: "Dinar argelino" },
  { codigo: "EGP", name: "Libra egipcia" },
  { codigo: "ERN", name: "Nakfa" },
  { codigo: "ETB", name: "Birr etíope" },
  { codigo: "FJD", name: "Dólar de Fiji" },
  { codigo: "FKP", name: "Libra malvinense" },
  { codigo: "GEL", name: "Lari" },
  { codigo: "GHS", name: "Cedi de Ghana" },
  { codigo: "GIP", name: "Libra de Gibraltar" },
  { codigo: "GMD", name: "Dalasi" },
  { codigo: "GNF", name: "Franco guineano" },
  { codigo: "GTQ", name: "Quetzal" },
  { codigo: "GYD", name: "Dólar guyanés" },
  { codigo: "HKD", name: "Dolar De Hong Kong" },
  { codigo: "HNL", name: "Lempira" },
  { codigo: "HRK", name: "Kuna" },
  { codigo: "HTG", name: "Gourde" },
  { codigo: "HUF", name: "Florín" },
  { codigo: "IDR", name: "Rupia" },
  { codigo: "ILS", name: "Nuevo Shekel Israelí" },
  { codigo: "INR", name: "Rupia india" },
  { codigo: "IQD", name: "Dinar iraquí" },
  { codigo: "IRR", name: "Rial iraní" },
  { codigo: "ISK", name: "Corona islandesa" },
  { codigo: "JMD", name: "Dólar Jamaiquino" },
  { codigo: "JOD", name: "Dinar jordano" },
  { codigo: "KES", name: "Chelín keniano" },
  { codigo: "KGS", name: "Som" },
  { codigo: "KHR", name: "Riel" },
  { codigo: "KMF", name: "Franco Comoro" },
  { codigo: "KPW", name: "Corea del Norte ganó" },
  { codigo: "KRW", name: "Won" },
  { codigo: "KWD", name: "Dinar kuwaití" },
  { codigo: "KYD", name: "Dólar de las Islas Caimán" },
  { codigo: "KZT", name: "Tenge" },
  { codigo: "LAK", name: "Kip" },
  { codigo: "LBP", name: "Libra libanesa" },
  { codigo: "LKR", name: "Rupia de Sri Lanka" },
  { codigo: "LRD", name: "Dólar liberiano" },
  { codigo: "LSL", name: "Loti" },
  { codigo: "LYD", name: "Dinar libio" },
  { codigo: "MAD", name: "Dirham marroquí" },
  { codigo: "MDL", name: "Leu moldavo" },
  { codigo: "MGA", name: "Ariary malgache" },
  { codigo: "MKD", name: "Denar" },
  { codigo: "MMK", name: "Kyat" },
  { codigo: "MNT", name: "Tugrik" },
  { codigo: "MOP", name: "Pataca" },
  { codigo: "MRO", name: "Ouguiya" },
  { codigo: "MUR", name: "Rupia de Mauricio" },
  { codigo: "MVR", name: "Rupia" },
  { codigo: "MWK", name: "Kwacha" },
  { codigo: "MXV", name: "México Unidad de Inversión (UDI)" },
  { codigo: "MYR", name: "Ringgit malayo" },
  { codigo: "MZN", name: "Mozambique Metical" },
  { codigo: "NAD", name: "Dólar de Namibia" },
  { codigo: "NGN", name: "Naira" },
  { codigo: "NIO", name: "Córdoba Oro" },
  { codigo: "NOK", name: "Corona noruega" },
  { codigo: "NPR", name: "Rupia nepalí" },
  { codigo: "NZD", name: "Dólar de Nueva Zelanda" },
  { codigo: "OMR", name: "Rial omaní" },
  { codigo: "PAB", name: "Balboa" },
  { codigo: "PEN", name: "Nuevo Sol" },
  { codigo: "PGK", name: "Kina" },
  { codigo: "PHP", name: "Peso filipino" },
  { codigo: "PKR", name: "Rupia de Pakistán" },
  { codigo: "PLN", name: "Zloty" },
  { codigo: "PYG", name: "Guaraní" },
  { codigo: "QAR", name: "Qatar Rial" },
  { codigo: "RON", name: "Leu rumano" },
  { codigo: "RSD", name: "Dinar serbio" },
  { codigo: "RUB", name: "Rublo ruso" },
  { codigo: "RWF", name: "Franco ruandés" },
  { codigo: "SAR", name: "Riyal saudí" },
  { codigo: "SBD", name: "Dólar de las Islas Salomón" },
  { codigo: "SCR", name: "Rupia de Seychelles" },
  { codigo: "SDG", name: "Libra sudanesa" },
  { codigo: "SEK", name: "Corona sueca" },
  { codigo: "SGD", name: "Dolar De Singapur" },
  { codigo: "SHP", name: "Libra de Santa Helena" },
  { codigo: "SLL", name: "Leona" },
  { codigo: "SOS", name: "Chelín somalí" },
  { codigo: "SRD", name: "Dólar de Suriname" },
  { codigo: "SSP", name: "Libra sudanesa Sur" },
  { codigo: "STD", name: "Dobra" },
  { codigo: "SVC", name: "Colon El Salvador" },
  { codigo: "SYP", name: "Libra Siria" },
  { codigo: "SZL", name: "Lilangeni" },
  { codigo: "THB", name: "Baht" },
  { codigo: "TJS", name: "Somoni" },
  { codigo: "TMT", name: "Turkmenistán nuevo manat" },
  { codigo: "TND", name: "Dinar tunecino" },
  { codigo: "TOP", name: "Pa anga" },
  { codigo: "TRY", name: "Lira turca" },
  { codigo: "TTD", name: "Dólar de Trinidad y Tobago" },
  { codigo: "TWD", name: "Nuevo dólar de Taiwán" },
  { codigo: "TZS", name: "Shilling tanzano" },
  { codigo: "UAH", name: "Hryvnia" },
  { codigo: "UGX", name: "Shilling de Uganda" },
  { codigo: "USN", name: "Dólar estadounidense (día siguiente)" },
  { codigo: "UYI", name: "Peso Uruguay en Unidades Indexadas (URUIURUI)" },
  { codigo: "UYU", name: "Peso Uruguayo" },
  { codigo: "UZS", name: "Uzbekistán Sum" },
  { codigo: "VEF", name: "Bolívar" },
  { codigo: "VND", name: "Dong" },
  { codigo: "VUV", name: "Vatu" },
  { codigo: "WST", name: "Tala" },
  { codigo: "XAF", name: "Franco CFA BEAC" },
  { codigo: "XAG", name: "Plata" },
  { codigo: "XAU", name: "Oro" },
  {
    codigo: "XBA",
    name: "Unidad de Mercados de Bonos Unidad Europea Composite (EURCO)",
  },
  {
    codigo: "XBB",
    name: "Unidad Monetaria de Bonos de Mercados Unidad Europea (UEM-6)",
  },
  {
    codigo: "XBC",
    name: "Mercados de Bonos Unidad Europea unidad de cuenta a 9 (UCE-9)",
  },
  {
    codigo: "XBD",
    name: "Mercados de Bonos Unidad Europea unidad de cuenta a 17 (UCE-17)",
  },
  { codigo: "XCD", name: "Dólar del Caribe Oriental" },
  { codigo: "XDR", name: "DEG (Derechos Especiales de Giro)" },
  { codigo: "XOF", name: "Franco CFA BCEAO" },
  { codigo: "XPD", name: "Paladio" },
  { codigo: "XPF", name: "Franco CFP" },
  { codigo: "XPT", name: "Platino" },
  { codigo: "XSU", name: "Sucre" },
  {
    codigo: "XTS",
    name: "Códigos reservados específicamente para propósitos de prueba",
  },
  { codigo: "XUA", name: "Unidad ADB de Cuenta" },
  { codigo: "YER", name: "Rial yemení" },
  { codigo: "ZAR", name: "Rand" },
  { codigo: "ZMW", name: "Kwacha zambiano" },
  { codigo: "ZWL", name: "Zimbabwe Dólar" },
];
let TipoRelaciones = [
  {
    codigo: "01",
    name: "01-Nota de crédito de los documentos relacionados",
  },
  {
    codigo: "02",
    name: "02-Nota de débito de los documentos relacionados",
  },
  {
    codigo: "03",
    name: "03-Devolución de mercancía sobre facturas o traslados previos",
  },
  {
    codigo: "04",
    name: "04-Sustitución de los CFDI previos",
  },
  {
    codigo: "05",
    name: "05-Traslados de mercancias facturados previamente",
  },
  {
    codigo: "06",
    name: "06-Factura generada por los traslados previos",
  },
  {
    codigo: "07",
    name: "07-CFDI por aplicación de anticipo",
  },
  {
    codigo: "08",
    name: "08-Factura generada por pagos en parcialidades",
  },
  {
    codigo: "09",
    name: "09-Factura generada por pagos diferidos",
  },
];

let TipoRelacion = function(codigo) {
  let result = "";
  if (codigo === undefined) return "";
  if (codigo === null) return "";
  if (codigo.length === 0) return "";
  const usos = TipoRelaciones;
  try {
    result = usos.filter((f) => f.codigo === codigo)[0].name;
  } catch (error) {
    result = codigo;
  }
  return result;
};
let FormasPago = [
  {
    codigo: "01",
    name: "Efectivo",
  },
  {
    codigo: "02",
    name: "Cheque nominativo",
  },
  {
    codigo: "03",
    name: "Transferencia electrónica de fondos",
  },
  {
    codigo: "04",
    name: "Tarjeta de crédito",
  },
  {
    codigo: "05",
    name: "Monedero electrónico",
  },
  {
    codigo: "06",
    name: "Dinero electrónico",
  },
  {
    codigo: "08",
    name: "Vales de despensa",
  },
  {
    codigo: "12",
    name: "Dación en pago",
  },
  {
    codigo: "13",
    name: "Pago por subrogación",
  },
  {
    codigo: "14",
    name: "Pago por consignación",
  },
  {
    codigo: "15",
    name: "Condonación",
  },
  {
    codigo: "17",
    name: "Compensación",
  },
  {
    codigo: "23",
    name: "Novación",
  },
  {
    codigo: "24",
    name: "Confusión",
  },
  {
    codigo: "25",
    name: "Remisión de deuda",
  },
  {
    codigo: "26",
    name: "Prescripción o caducidad",
  },
  {
    codigo: "27",
    name: "A satisfacción del acreedor",
  },
  {
    codigo: "28",
    name: "Tarjeta de débito",
  },
  {
    codigo: "29",
    name: "Tarjeta de servicios",
  },
  {
    codigo: "30",
    name: "Aplicación de anticipos",
  },
  {
    codigo: "31",
    name: "Intermediario pagos",
  },
  {
    codigo: "99",
    name: "Por definir",
  },
];

let FormasPagoConcat = [
  {
    codigo: "01",
    name: "01 - Efectivo",
  },
  {
    codigo: "02",
    name: "02 - Cheque nominativo",
  },
  {
    codigo: "03",
    name: "03 - Transferencia electrónica de fondos",
  },
  {
    codigo: "04",
    name: "04 - Tarjeta de crédito",
  },
  {
    codigo: "05",
    name: "05 - Monedero electrónico",
  },
  {
    codigo: "06",
    name: "06 - Dinero electrónico",
  },
  {
    codigo: "08",
    name: "08 - Vales de despensa",
  },
  {
    codigo: "12",
    name: "12 - Dación en pago",
  },
  {
    codigo: "13",
    name: "13 - Pago por subrogación",
  },
  {
    codigo: "14",
    name: "14 - Pago por consignación",
  },
  {
    codigo: "15",
    name: "15 - Condonación",
  },
  {
    codigo: "17",
    name: "17 - Compensación",
  },
  {
    codigo: "23",
    name: "23 - Novación",
  },
  {
    codigo: "24",
    name: "24 - Confusión",
  },
  {
    codigo: "25",
    name: "25 - Remisión de deuda",
  },
  {
    codigo: "26",
    name: "26 - Prescripción o caducidad",
  },
  {
    codigo: "27",
    name: "27 - A satisfacción del acreedor",
  },
  {
    codigo: "28",
    name: "28 - Tarjeta de débito",
  },
  {
    codigo: "29",
    name: "29 - Tarjeta de servicios",
  },
  {
    codigo: "30",
    name: "30 - Aplicación de anticipos",
  },
  {
    codigo: "31",
    name: "31 - Intermediario pagos",
  },
  {
    codigo: "99",
    name: "99 - Por definir",
  },
];

let FormaPago = function(codigo) {
  let result = "";
  if (codigo === undefined) return "";
  if (codigo === null) return "";
  if (codigo.length === 0) return "";
  const formas = FormasPago;
  try {
    result = formas.filter((f) => f.codigo === codigo)[0].name;
  } catch (error) {
    result = codigo;
  }
  return result;
};

let Periodicidad = function(codigo) {
  let result = "";
  if (codigo === undefined) return "";
  if (codigo === null) return "";
  if (codigo.length === 0) return "";
  const periodicidades = [
    {
      codigo: "01",
      name: "Diario",
    },
    {
      codigo: "02",
      name: "Semanal",
    },
    {
      codigo: "03",
      name: "Catorcenal",
    },
    {
      codigo: "04",
      name: "Quincenal",
    },
    {
      codigo: "05",
      name: "Mensual",
    },
    {
      codigo: "06",
      name: "Bimestral",
    },
    {
      codigo: "07",
      name: "Unidad obra",
    },
    {
      codigo: "08",
      name: "Comisión",
    },
    {
      codigo: "09",
      name: "Precio alzado",
    },
    {
      codigo: "10",
      name: "Decenal",
    },
    {
      codigo: "99",
      name: "Otra Periodicidad",
    },
  ];
  try {
    result = periodicidades.filter((f) => f.codigo === codigo)[0].name;
  } catch (error) {
    result = codigo;
  }
  return result;
};

let RegimenNomina = function(codigo) {
  let result = "";
  if (codigo === undefined) return "";
  if (codigo === null) return "";
  if (codigo.length === 0) return "";
  const regimenes = [
    {
      codigo: "02",
      name:
        "Sueldos. Incluye ingresos señalados en la fracción I del artículo 94 de LISR",
    },
    {
      codigo: "03",
      name: "Jubilados",
    },
    {
      codigo: "04",
      name: "Pensionados",
    },
    {
      codigo: "05",
      name: "Asimilados Miembros Sociedades Cooperativas Producción",
    },
    {
      codigo: "06",
      name: "Asimilados Integrantes Sociedades Asociaciones Civiles",
    },
    {
      codigo: "07",
      name: "Asimilados Miembros consejos",
    },
    {
      codigo: "08",
      name: "Asimilados comisionistas",
    },
    {
      codigo: "09",
      name: "Asimilados Honorarios",
    },
    {
      codigo: "10",
      name: "Asimilados acciones",
    },
    {
      codigo: "11",
      name: "Asimilados otros",
    },
    {
      codigo: "12",
      name: "Jubilados o Pensionados",
    },
    {
      codigo: "13",
      name: "Indemnización o Separación",
    },
    {
      codigo: "99",
      name: "Otro Régimen",
    },
  ];
  try {
    result = regimenes.filter((f) => f.codigo === codigo)[0].name;
  } catch (error) {
    result = codigo;
  }
  return result;
};


let ComplementosCFDI = [
  
  {
    codigo: "acreditamientoIEPS",
    name: "Acreditamiento del IEPS",
  },
  {
    codigo: "Aerolineas",
    name: "Aerolíneas",
  },
  {
    codigo: "certificadodedestruccion",
    name: "Certificado de destrucción",
  },
  {
    codigo: "ComercioExterior",
    name: "Comercio Exterior",
  },
  {
    codigo: "CartaPorte",
    name: "Complemento Carta Porte",
  },
  {
    codigo: "Divisas",
    name: "Compra venta de divisas",
  },
  {
    codigo: "ConsumoDeCombustibles",
    name: "Consumo de combustibles",
  },
  {
    codigo: "Donatarias",
    name: "Donatarias",
  },
  {
    codigo: "EstadoDeCuentaCombustible",
    name: "Estado de cuenta de combustibles de monederos electrónicos",
  },
  {
    codigo: "GastosHidrocarburos",
    name: "Complemento de Hidrocarburos",
  },
  {
    codigo: "INE",
    name: "INE",
  },
  {
    codigo: "instEducativas",
    name: "Instituciones educativas privadas",
  },
  {
    codigo: "LeyendasFiscales",
    name: "Leyendas Fiscales",
  },
  {
    codigo: "NotariosPublicos",
    name: "Notarios Públicos",
  },
  {
    codigo: "PagoEnEspecie",
    name: "Pago en especie",
  },
  {
    codigo: "PFintegranteCoordinado",
    name: "Persona física integrante de coordinado",
  },
  /*{
    codigo: "Pagos",
    name: "Recepción de pagos",
  },
  {
    codigo: "Nomina",
    name: "Recibo de pago de nómina",
  },*/
  {
    codigo: "renovacionysustitucionvehiculos",
    name: "Renovación y sustitución de vehículos",
  },
  {
    codigo: "detallista",
    name: "Sector de ventas al detalle (Detallista)",
  },
  {
    codigo: "parcialesconstruccion",
    name: "Servicios parciales de construcción",
  },
  {
    codigo: "Complemento_SPEI",
    name: "SPEI de tercero a tercero",
  },
  {
    codigo: "PorCuentadeTerceros",
    name: "Terceros",
  },
  {
    codigo: "TuristaPasajeroExtranjero",
    name: "TuristaPasajeroExtranjero",
  },
  {
    codigo: "ValesDeDespensa",
    name: "Vales de despensa",
  },
  {
    codigo: "VehiculoUsado",
    name: "Vehículo usado",
  },
  {
    codigo: "VentaVehiculos",
    name: "Venta de vehículos",
  },
];


let TipoNomina = function(codigo) {
  if (codigo === "O") return "Ordinaria";
  else return "Extraordinaria";
};

let getTipoComprobante = function(tipoDoc) {
  let tipo = "";

  switch (tipoDoc) {
    case "I":
      tipo = "Ingreso";
      break;
    case "E":
      tipo = "Egreso";
      break;
    case "T":
      tipo = "Traslado";
      break;
    case "P":
      tipo = "Pago";
      break;
      default:
        tipo = tipoDoc;
        break;
  }
  return tipo;
};

let parseDoctype = function(docType) {

  let doc = "";
  switch (docType) {
      case "I":
          doc = "Ingreso";
          break;
      case "E":
          doc = "Egreso";
          break;
      case "N":
          doc = "Nomina";
          break;
      case "T":
          doc = "Traslado";
          break;
      case "P":
          doc = "Pago";
          break;                    
      default:
          doc = "";
          break;

  }

  return doc;
}

let formatFecha = function(fecha) {
  if (fecha === null) return "";
  return fecha.replace("T", " ");
};

let getFechaLimite = function(esConcepto) {

  if(esConcepto){
    let fechaConcepto = new Date(2021, 0, 1).toISOString().substr(0, 7);
    return fechaConcepto;
  }
  let anteriores = 5;
  if (store.state.entity == "MEX031128GI6") anteriores = 9;

  let date = new Date();
  let fechaLimite = new Date(date.getFullYear() - anteriores, 0, 1);
  return fechaLimite.toISOString().substr(0, 7);
};


let getFechaLimiteInferior = function(fechaInferior) {
  let fechaLimite;
    
  if (fechaInferior) {
  
    if(store.state.entity == "MAS101218N40")
    {
      fechaLimite = new Date("2019-01-01");
    }
    else
      fechaLimite = new Date(fechaInferior);
  } else {
    let anteriores = 5;
    if (store.state.entity == "MEX031128GI6") anteriores = 9;

    let date = new Date();
    fechaLimite = new Date(date.getFullYear() - anteriores, 0, 1);
  }

  return fechaLimite.toISOString().substr(0, 7);
};

let getDiferenciaFechaHoy = function(fecha) {
  var fechaAnterior = new Date(fecha).getTime();
  var hoy = Date.now();

  var diff = hoy - fechaAnterior;

  return diff / (1000 * 60 * 60 * 24);
};
export default {
  currencyFormat,
  formatMoney,
  formatTax,
  formatCommas,
  exportCSVFile,
  convertToCSV,
  dateNow,
  dateNowShort,
  dateNowCSV,
  ConvertToEmpty,
  ConvertToNoAplica,
  ConvertToZero,
  Base64Encode,
  Base64Decode,
  Base64ToArrayBuffer,
  openNewBackgroundTab,
  ToPDF,
  ToXML,
  IsNotNull,
  LastDayMonth,
  docTypeTranslate,
  RegimenFiscal,
  Impuesto,
  UsoCFDI,
  TipoRelacion,
  FormaPago,
  Periodicidad,
  RegimenNomina,
  TipoNomina,
  getTipoComprobante,
  getDiferenciaFechaHoy,
  getFechaLimite,
  getFechaLimiteInferior,
  Regimenes,
  TipoRelaciones,
  Monedas,
  UsosCFDIs,
  FormasPago,
  UsosCFDIsConcat,
  RegimenesConcat,
  MonedasConcat,
  FormasPagoConcat,
  exportCSVFileV1,
  FechaActual,
  FechaActualSinHoras,
  FechaActualReal,
  formatFecha,
  parseDoctype,
  ComplementosCFDI
};

export function JSONToCSV(JSONData, ReportTitle, ShowLabel) {
  var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
  arrData = arrData.map(
    ({ _rid, _self, _etag, _attachments, _ts, ...rest }) => rest
  );
  var CSV = "";
  //CSV += ReportTitle + '\r\n\n';
  if (ShowLabel) {
    var row = "";
    for (var index in arrData[0]) {
      row += index + ",";
    }
    row = row.slice(0, -1);
    CSV += row + "\r\n";
  }
  for (var i = 0; i < arrData.length; i++) {
    var row = "";
    for (var index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);
    CSV += row + "\r\n";
  }

  if (CSV == "") {
    alert("Invalid data");
    return;
  }

  var fileName = "";
  fileName += ReportTitle.replace(/ /g, "_");
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);
  var link = document.createElement("a");
  link.href = uri;
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
