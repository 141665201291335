import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    overlay: false,
    entity: null,
    showDrawer: false,
    accountType: "",
    usuario: "",
    fromCrono: null,  
    userLongName: "",
    isRetencion: false,
    dateInterval: null,
    tipoDocTs: "",
    hasFiel: false,
    fielExpirationDate: "",
    hasAutSatDownload: false,
    isAdministrator : false,
    isPayroll:false,
    isInvoice: false,
    allowIssued: false,
    allowReceived: false,
    isContractAccepted: false,
    certFiel: "",
    keyFiel: "",
    passFiel: "",
    blData: null,
    blRoute: "",
    showNotas:false,
    selectedNote:"",
    genericObject: null,
    alertError: false,
    alertOk: false,
    alertMessage: "",
    periodoEx: "",
    contextoEx: "E",
    partitionSchema: null,
    newContractSign: false,
    firmadoZoho: false,
    idContratoZoho: null,
    mostrarHeader: false

  },
  mutations: {
    setToken(state, token_value) {
      state.token = token_value;
    },
    setOverlay(state, overlay_value) {
      state.overlay = overlay_value;
    },
    setEntity(state, entity_value) {
      state.entity = entity_value;      
    },
    setShowDrawer(state, drawer_value) {
      state.showDrawer = drawer_value;
    },
    setAccountType(state, accountType_value) {
      state.accountType = accountType_value;
    },
    setUserName(state, user_value) {
      state.usuario = user_value;
    },
    setUserLongName(state, userLong_value) {
      state.userLongName = userLong_value;
    },
    setFromCrono(state, fromCrono_value) {
      state.fromCrono = fromCrono_value;
    },
    setIsRetencion(state, isRetencion_Value) {
      state.isRetencion = isRetencion_Value;
    },
    setDateInterval(state, dateInterval_Value) {
      state.dateInterval = dateInterval_Value;
    },
    setTipoDocTs(state, tipoDocTs_Value) {
      state.tipoDocTs = tipoDocTs_Value;
    },
    setHasFiel(state, hasFiel_Value) {
      state.hasFiel = hasFiel_Value;
    },
    setFielExpirationDate(state, fielExpirationDate_Value) {
      state.fielExpirationDate = fielExpirationDate_Value;
    },
    setHasAutSatDownload(state, hasAutSatDownload_Value) {
      state.hasAutSatDownload = hasAutSatDownload_Value;
    },
    setIsAdministrator(state, isAdministrator_Value) {
      state.isAdministrator = isAdministrator_Value;
    },
    setIsPayroll(state, isPayroll_Value) {
      state.isPayroll = isPayroll_Value;
    },
    setIsInvoice(state, isInvoice_Value) {
      state.isInvoice = isInvoice_Value;
    },
    setAllowIssued(state, allowIssued_Value) {
      state.allowIssued = allowIssued_Value;
    },
    setAllowReceived(state, allowReceived_Value) {
      state.allowReceived = allowReceived_Value;
    },
    setIsContractAccepted(state, isContractAccepted_Value) {
      state.isContractAccepted = isContractAccepted_Value;
    },
    setCertFiel(state, certFiel_Value) {
      state.certFiel = certFiel_Value;
    },
    setKeyFiel(state, keyFiel_Value) {
      state.keyFiel = keyFiel_Value;
    },
    setPassFiel(state, passFiel_Value) {
      state.passFiel = passFiel_Value;
    },
    setBlData(state, blData_Value) {
      state.blData = blData_Value;
    },
    setBlRoute(state, blRoute_Value) {
      state.blRoute = blRoute_Value;
    },
    setShowNotas(state, showNotas_value) {
      state.showNotas = showNotas_value;
    },
    setSelectedNote(state, selectedNote_value) {
      state.selectedNote = selectedNote_value;
    },
    setGenericObject(state, genericObject_value) {
      state.genericObject = genericObject_value;
    },
    setAlertError(state, alertError_value) {
      state.alertError = alertError_value;
    },
    setAlertOk(state, alertOk_value) {
      state.alertOk = alertOk_value;
    },
    setAlertMessage(state, alertMessage_value) {
      state.alertMessage = alertMessage_value;
    },
    setPeriodoEx(state, periodoEx_value) {
      state.periodoEx = periodoEx_value;
    },
    setContextoEx(state, contextoEx_value) {
      state.contextoEx = contextoEx_value;
    },
    setPartitionSchema(state, partitionSchema_value) {
      state.partitionSchema = partitionSchema_value;
    },
    setNewContractSign(state, newContractSign_value) {
      state.newContractSign = newContractSign_value;
    }
    ,
    setFirmadoZoho(state, firmadoZoho_value) {
      state.firmadoZoho = firmadoZoho_value;
    },
    setIdContratoZoho(state, idContratoZoho_value) {
      state.idContratoZoho = idContratoZoho_value;
    },
    setMostrarHeader(state, mostrarHeader_value) {
      state.mostrarHeader = mostrarHeader_value;
    }

    

  },
  actions: {}
});
