var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-10",attrs:{"fluid":""}},[_c('v-row',[_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.showDialogEliminarReport),callback:function ($$v) {_vm.showDialogEliminarReport=$$v},expression:"showDialogEliminarReport"}},[_c('v-card',{attrs:{"dense":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showDialogEliminarReport = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Confirmación Eliminar Reporte: "+_vm._s(this.itemDesc))]),_c('v-spacer')],1),_c('v-card-text',[_c('br'),_c('h3',[_vm._v("Este reporte sera eliminado permanentemente")]),_c('h3',[_vm._v("¿Está seguro de continuar con esta acción?")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.EliminarReporte}},[_vm._v("Confirmar")]),_c('v-btn',{attrs:{"color":"danger","text":""},on:{"click":function($event){_vm.showDialogEliminarReport = false}}},[_vm._v("Cerrar")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Integración IVA-SAT "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.token.length),expression:"$store.state.token.length"}],attrs:{"icon":"","color":"primary","href":"https://soporte.mysuitemex.com/portal/es/kb/articles/reporte-de-integracion-iva-sat","target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}])},[_c('span',[_vm._v("Manual")])])],1)]),_c('v-col',{attrs:{"cols":"11"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-card',{staticClass:"paddingCard"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"px-2 mx-md-10",attrs:{"cols":"4"}},[_c('date-range-picker',{attrs:{"fecha-limite-inferior":"2021-01-01"},model:{value:(_vm.selectedDates),callback:function ($$v) {_vm.selectedDates=$$v},expression:"selectedDates"}})],1),_c('v-col',{staticClass:"px-2 mx-md-10",attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.tiposDoc,"label":"Tipo Comprobante","outlined":"","item-text":"opcion","item-value":"valor"},model:{value:(_vm.tipoDoc),callback:function ($$v) {_vm.tipoDoc=$$v},expression:"tipoDoc"}})],1),_c('v-col',{staticClass:"px-2 mx-md-10",attrs:{"cols":"12","md":"1"}},[_c('v-select',{staticClass:"min-width-control-xl",attrs:{"items":_vm.itemsOrigen,"label":"Contexto","outlined":""},model:{value:(_vm.contexto),callback:function ($$v) {_vm.contexto=$$v},expression:"contexto"}})],1),_c('v-col',{staticClass:"d-flex px-10",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"my-1",attrs:{"counter":"0","label":"Descripción*","maxlength":"100"},model:{value:(_vm.descripcionIn),callback:function ($$v) {_vm.descripcionIn=$$v},expression:"descripcionIn"}})],1),_c('v-col',{staticClass:"px-2 mx-md-10",attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.showCreateButton,"color":"primary","white":"","large":""},on:{"click":_vm.requestItems}},on),[_vm._v("Solicitar Reporte")])]}}])},[_c('span',[_vm._v("Solicitar reporte sin limite documentos")])])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Reportes Solicitados")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.ObtenerReportes}},on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refrescar Tabla")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12"}},[(_vm.itemsGrandes.length > 0)?_c('v-simple-table',{attrs:{"height":"600","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Descripción")]),_c('th',[_vm._v("Fecha Solicitud")]),_c('th',[_vm._v("Fecha Inicial")]),_c('th',[_vm._v("FechaFinal")]),_c('th',[_vm._v("Contexto")]),_c('th',[_vm._v("Tipo de Documento")]),_c('th',[_vm._v("Notas")]),_c('th',[_vm._v("Mensaje")]),_c('th',[_vm._v("Disponible")]),_c('th',[_vm._v("Resultado")]),_c('th'),_c('th')])]),_c('tbody',_vm._l((_vm.itemsGrandes),function(item){return _c('tr',{key:item.id,staticClass:"altura"},[_c('td',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.requestDate)+" ")]),_c('td',[_vm._v(" "+_vm._s(JSON.parse(item.commands).dateInit)+" ")]),_c('td',[_vm._v(" "+_vm._s(JSON.parse(item.commands).dateEnd)+" ")]),_c('td',[_vm._v(" "+_vm._s(JSON.parse(item.commands).context == "E" ? "Emitido" : "Recibido")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.parseTipoDoc(JSON.parse(item.commands).docType))+" ")]),_c('td',[_vm._v(" "+_vm._s(JSON.parse(item.commands).notes)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.message)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.ready ? "Si" : "No")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.ready ? item.result ? "Exitoso" : "Fracasado" : "Procesando")+" ")]),_c('td',{staticClass:"pa-0 md-0"},[(item.ready && item.result)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"#4aa832"},on:{"click":function($event){return _vm.DescargarReporte(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Reporte")])]):_vm._e()],1),_c('td',{staticClass:"pa-0 md-0"},[(item.ready)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.ConfirmarEliminarReporte(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Reporte")])]):_vm._e()],1)])}),0)]},proxy:true}],null,false,3925737235)}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }