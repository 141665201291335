<template>
    <div class="py-10">
        <v-container fluid class="px-10">
            <v-row justify="center">


                <v-dialog v-model="showDialogReports" persistent>
                    <v-col cols="12">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="showDialogReports = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click="ObtenerReportes" icon v-on="on">
                                            <v-icon>mdi-cached</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refrescar Tabla</span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-btn @click="showDialogCrear = true" large color="second">
                                    <v-icon>mdi-plus</v-icon>Solicitar Reporte
                                </v-btn>
                            </v-toolbar>

                            <v-row>
                                <v-col cols="12">
                                    <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{
                    errorMessageIn }}
                                    </v-alert>
                                    <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{
                    okMessageIn
                }}</v-alert>
                                </v-col>
                                <v-col cols="12">
                                    <v-simple-table height="600" fixed-header dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Descripción</th>
                                                    <th>Fecha Solicitud</th>
                                                    <th>Contexto</th>
                                                    <th>Tipo Comprobante</th>
                                                    <th>Fecha Inicial</th>
                                                    <th>Fecha Final</th>
                                                    <th>RFC</th>
                                                    <th>Mensaje</th>
                                                    <th>Disponible</th>
                                                    <th>Resultado</th>

                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                                                    <td>
                                                        {{ item.name }}
                                                    </td>
                                                    <td>
                                                        {{ item.description }}
                                                    </td>
                                                    <td>
                                                        {{ item.requestDate }}
                                                    </td>
                                                    <td>
                                                        {{
                    JSON.parse(item.commands).context == "E" ? "Emitido" :
                        "Recibido"
                }}
                                                    </td>
                                                    <td>
                                                        {{ parseDocType(JSON.parse(item.commands).docType) }}
                                                    </td>
                                                    <td style="white-space: nowrap">
                                                        {{ JSON.parse(item.commands).dateInit }}
                                                    </td>
                                                    <td style="white-space: nowrap">
                                                        {{ JSON.parse(item.commands).dateEnd }}
                                                    </td>
                                                    <td>
                                                        {{ JSON.parse(item.commands).taxid }}
                                                    </td>
                                                    <td>
                                                        {{ item.message }}
                                                    </td>
                                                    <td>
                                                        {{ item.ready ? "Si" : "No" }}
                                                    </td>
                                                    <td>
                                                        {{
                    item.ready
                        ? item.result
                            ? "Exitoso"
                            : "Fracasado"
                        : "Procesando"
                }}
                                                    </td>
                                                    <td class="pa-0 md-0">
                                                        <v-tooltip left v-if="item.ready && item.result">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn @click="DescargarReporte(item.id)" icon
                                                                    color="#4aa832" v-on="on">
                                                                    <v-icon>mdi-download</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Descargar Reporte</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td class="pa-0 md-0">
                                                        <v-tooltip left v-if="item.ready">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn @click="ConfirmarEliminarReporte(item)" icon
                                                                    color="red" v-on="on">
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Eliminar Reporte</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-dialog>



                <v-dialog v-model="showDialogCrear" persistent max-width="800px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="cerrarCrearSolicitud">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" justify="center">
                                    <date-range-picker ref="dateRange" v-model="selectedDatesIn"></date-range-picker>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="rfcSearchIn" counter="0" label="RFC" class="my-1"
                                        maxlength="13">
                                    </v-text-field>
                                </v-col>
                                <v-col class="d-flex" cols="12" sm="6">
                                    <v-select :items="itemsSelect" v-model="tipoDocIn" label="Tipo de Comprobante"
                                        outlined>
                                    </v-select>
                                </v-col>
                                <v-col class="d-flex" cols="12" md="6">
                                    <v-select :items="itemsOrigen" v-model="contextoIn" @change="cambioOrigen"
                                        label="Contexto" outlined>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6" >
                                <v-select class="min-width-control-xl" :items="itemsRelaciones" item-text="name"
                                    item-value="codigo" v-model="relacionesIn" label="Tipo Relación" outlined
                                   ></v-select>
                            </v-col>
                                <v-col md="10" justify="center">
                                    <v-text-field v-model="nombreIn" counter="0" label="Nombre" class="my-1"
                                        maxlength="100">
                                    </v-text-field>
                                </v-col>
                                <v-col md="10" justify="center">
                                    <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1"
                                        maxlength="100">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider class="mt-12"></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="requestItems" text large
                                :disabled="!showCreateButton">Solicitar
                                Reporte
                            </v-btn>
                            <v-btn color="danger" text @click="cerrarCrearSolicitud">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="showDialogEliminarReport = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Confirmación Eliminar Reporte:
                                {{ this.itemDesc }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <br />
                            <h3>Este reporte sera eliminado permanentemente</h3>
                            <h3>¿Está seguro de continuar con esta acción?</h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
                            <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>



                <v-dialog v-model="showDialogRelacionados" persistent max-width="1500px">
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="showDialogRelacionados = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Documentos Relacionados del documento :
                                {{ itemSelected.serie }} -
                                {{ itemSelected.folio }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th v-if="esRecibido">RFC Emisor</th>
                                            <th v-if="esRecibido">Razon Social Emisor</th>
                                            <th v-if="esEmitido">RFC Receptor</th>
                                            <th v-if="esEmitido">Razon Social Receptor</th>
                                            <th>Serie</th>
                                            <th>Folio</th>
                                            <th>Tipo Comprobante</th>
                                            <th>Moneda</th>
                                            <th>Total</th>
                                            <th style="white-space: nowrap">Fecha Emisión</th>
                                            <!-- <th>Fecha Cancelación</th> -->
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in itemsRel" :key="item.id" class="altura">
                                            <td v-if="esRecibido">{{ item.emisor.rfc }}</td>
                                            <td v-if="esRecibido">{{ item.emisor.nombre }}</td>
                                            <td v-if="esEmitido">{{ item.receptor.rfc }}</td>
                                            <td v-if="esEmitido">{{ item.receptor.nombre }}</td>
                                            <td>{{ item.serie }}</td>
                                            <td>{{ item.folio }}</td>
                                            <td>
                                                {{ getTipoComprobante(item.tipo_comprobante) }}
                                            </td>
                                            <td>{{ item.moneda }}</td>
                                            <td>{{ currencyFormat(item.total) }}</td>
                                            <td>{{ item.fecha_emision.substr(0, 10) }}</td>
                                            <!-- <td>
                                            {{ item.fecha_cancelacion }}
                                        </td> -->
                                            <td class="pa-0 md-0">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="retrieveXML(item)" icon color="blue" v-on="on">
                                                            <v-icon>mdi-xml</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Descargar XML</span>
                                                </v-tooltip>
                                            </td>
                                            <td class="pa-0 md-0">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="retrievePDF(item)" icon color="red" v-on="on">
                                                            <v-icon>mdi-file-pdf-box</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Descargar PDF</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="showDialogRelacionados = false">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="showDialogStatus" persistent max-width="700px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="cerrarStatus">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Detalle documento : {{ itemSelected.serie }} -
                                {{ itemSelected.folio }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table class="ma-3">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Estado</th>
                                            <th>Cancelable</th>
                                            <th>Estado Cancelación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="altura">
                                            <td>{{ selectedStatus.estado }}</td>
                                            <td>{{ selectedStatus.cancelable }}</td>
                                            <td>{{ selectedStatus.estado_cancelacion }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h3>Análisis de CFDI vs CFDI's Relacionados

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                                    href="https://soporte.mysuitemex.com/portal/es/kb/articles/analisis-de-cfdi-vs-cfdi-s-relacionados"
                                    target="_blank">
                                    <v-icon>mdi-help-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Manual</span>
                        </v-tooltip>
                    </h3>
                </v-col>
                <v-col>
                    <v-card class="paddingCard">
                        <v-row>
                            <v-col cols="12" md="4" class="px-2 mx-md-10">
                                <date-range-picker v-model="selectedDates"
                                    :disabled="dateTs.length > 0"></date-range-picker>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-text-field class="min-width-control-xl" :disabled="dateTs.length > 0"
                                    v-model="rfcSearch" @change="cambio = true" counter="0" :label="labelRfc"
                                    maxlength="13"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-select class="min-width-control-xl" :items="itemsSelect" v-model="tipoDoc"
                                    label="Tipo de Comprobante" outlined :disabled="dateTs.length > 0"></v-select>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto"
                                    @change="items = []" label="Contexto" outlined
                                    :disabled="dateTs.length > 0"></v-select>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-select class="min-width-control-xl" :items="itemsRelaciones" item-text="name"
                                    item-value="codigo" v-model="relaciones" label="Tipo Relación" outlined
                                    :disabled="dateTs.length > 0"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                                <v-btn color="primary" white large v-on:click="getReport"
                                    :disabled="!showSearchButton">Mostrar
                                    Listado</v-btn>

                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                                        <v-btn color="primary" white large v-on="on"
                                            v-on:click="ObtenerReportes">Reportes
                                            Bajo Demanda
                                        </v-btn>
                                    </template>
                                    <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="items.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
                            {{ totalDocumentos }}</v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>

                        <!-- <v-tooltip top class="d-none d-sm-flex">
                            <template v-slot:activator="{ on }">
                                <v-icon class="d-none d-sm-flex" @click="getReporteConciliatorioCSV" v-on="on"
                                    :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
                            </template>
                            <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
                        </v-tooltip>
                        -->

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>


                        <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :initialized="initGrid" :allowResizing="'None'">

                            <wj-flex-grid-column v-if="esRecibido" :header="'RFC Emisor'" :binding="'emisor_rfc'"
                                width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esRecibido" :header="'Razón Social'" :binding="'emisor_nombre'"
                                width="4*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*"
                                :isReadOnly="true" />

                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor_rfc'"
                                width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'receptor_nombre'"
                                width="4*" :isReadOnly="true" />



                            <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*"
                                :isReadOnly="true" />
                                <wj-flex-grid-column :header="'Forma de Pago'" width="2*" :isReadOnly="true"
                                cssClass="main-column">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getFormaPago(cell.item.forma_pago) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>

                            <wj-flex-grid-column :header="'Documentos Relacionados'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell">

                                    <table v-if="cell.item.cfdi_relacionados">
                                        <tbody>
                                            <tr class="borde">
                                                <th class="text-no-wrap caption">
                                                    <strong>Relación</strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>UUIDs</strong>
                                                </th>

                                            </tr>
                                            <template>
                                                <tr class="altura caption" :key="index"
                                                    v-for="(relacionado, index) in cell.item.cfdi_relacionados">
                                                    <td class="text-no-wrap" style="width: 10%">
                                                        {{ relacionado.relacion }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 90%" align="center">
                                                        <span v-for="(uuidIn, index) in relacionado.uuids">
                                                            {{ uuidIn }}<br v-if="index < relacionado.uuids.length - 1">
                                                        </span>
                                                    </td>

                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>


                                    <table v-if="cell.item.uuids">
                                        <tbody>
                                            <tr class="borde">
                                                <th class="text-no-wrap caption">
                                                    <strong>Relación</strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>UUIDs</strong>
                                                </th>

                                            </tr>
                                            <template>
                                                <tr class="altura caption" :key="index"
                                                    v-for="(uuidIn, index) in cell.item.uuids">
                                                    <td class="text-no-wrap" style="width: 10%">
                                                        {{ cell.item.relacion }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 90%" align="center">
                                                        <span>
                                                            {{ uuidIn }}<br v-if="index < relacionado.uuids.length - 1">
                                                        </span>
                                                    </td>

                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>



                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="ConsultarEstadoDoc(cell.item)" icon color="orange" v-on="on">
                                                <v-icon>mdi-information-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Consultar Estado del Documento</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="ConsultarDocsRelacionados(cell.item)" icon color="primary"
                                                v-on="on">
                                                <v-icon>mdi-file-tree</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Consultar Documentos Relacionados</span>
                                    </v-tooltip>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>

                        </wj-flex-grid>

                    </v-card>
                    <br />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';

import helpers from "../../scripts/helpers.js";
import {
    JSONToCSV
} from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
import Dialogo from '../Componentes/DialogoBuscar.vue';

export default {
    name: "Relacionados",
    components: {
        DateRangePicker,
        Dialogo
    },
    data: () => ({
        itemFind: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
            retencion_isr: 0,
            retencion_iva: 0,
            retencion_ieps: 0,
            traslado_iva: 0,
            traslado_ieps: 0,
        },
        selectedDates: [],
        selectedDatesIn: [],
        selectedRows: [],
        contexto: "Emitidos",
        showDialog: false,
        showDialogStatus: false,
        goPage: 1,
        selectedStatus: {
            estado: "",
            cancelable: "",
            estado_cancelacion: "",
        },
        cambio: false,
        folioDoc: "",
        modal: false,




        search: "",
        offest: 0,
        limit: 50,
        pageSize: "50",
        noPagina: 1,
        totalPaginas: 1,
        doctosPagina: 0,
        totalIngresos: 0,
        totalEgresos: 0,
        totalTraslados: 0,
        totalPagos: 0,
        totalDocumentos: 0,
        paginations: ["25", "50", "100", "150", "200"],
        items: [],
        tipoDoc: "Seleccionar Todos",
        itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso"],
        landscape: false,
        title: "",
        csvData: [],
        csvDataT: [],
        currentOrder: "DESC",
        currentOrderColumn: "nfecha_emision",
        rfcRClass: false,
        serieClass: false,
        fechaEClass: true,
        rfcEClass: false,
        folioClass: false,
        monedaClass: false,
        tipo_comprobanteClass: false,
        metodo_pagoClass: false,
        itemSelected: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
            retencion_isr: 0,
            retencion_iva: 0,
            retencion_ieps: 0,
            traslado_iva: 0,
            traslado_ieps: 0,
        },
        rfcSearch: "",
        dateTs: "",
        showDialogRelacionados: false,
        itemsRel: [],
        showDialogEliminarReport: false,
        showDialogReports: false,
        showDialogCrear: false,
        errorMessageIn: "",
        okMessageIn: "",
        alert_errorIn: false,
        alert_okIn: false,
        itemsGrandes: [],

        rfcSearchIn: "",
        descripcionIn: "",
        nombreIn: "",
        itemDesc: "",
        tipoDocIn: "Seleccionar Todos",
        contextoIn: "Emitidos",
        relaciones: "",
        relacionesIn: ""
    }),
    created() {
        this.$store.commit("setIsRetencion", false);
        this.$store.commit("setBlData", null);
        let dataCronologia = this.$store.state.fromCrono;
        let dataInterval = this.$store.state.dateInterval;

        if (dataCronologia === null && dataInterval === null) return;

        if (dataCronologia !== null) {
            this.rfcSearch = dataCronologia.rfc;
            this.selectedDates[0] = dataCronologia.year + "-01-01";
            this.selectedDates[1] = dataCronologia.year + "-12-31";
            this.$store.commit("setFromCrono", null);
        }

        if (dataInterval !== null) {
            //alert(JSON.stringify(dataInterval));
            this.selectedDates[0] = dataInterval.dateInit;
            this.selectedDates[1] = dataInterval.dateEnd;
            this.dateTs = dataInterval.dateTs;
            this.tipoDoc =
                this.$store.state.tipoDocTs === "" ?
                    "Seleccionar Todos" :
                    this.$store.state.tipoDocTs;
            this.$store.commit("setTipoDocTs", "");
            this.$store.commit("setDateInterval", null);
        }

        this.getReport();
    },
    methods: {
        getFormaPago(formaP) {
            return helpers.FormaPago(formaP);
        },
        cerrarCrearSolicitud(){
            this.nombreIn = "";
            this.descripcionIn = "";
            this.selectedDatesIn = [];
            this.$refs.dateRange.limpiarFechas();
            this.contextoIn = "Emitidos";
            this.tipoDocIn = "Seleccionar Todos",
                this.rfcSearch = "";
                this.relacionesIn = "";
            this.showDialogCrear = false;
        },
        

        initGrid: function (grid) {

            for (let i = 0; i < grid.columns.length - 1; i++) {
                grid.autoSizeColumn(i);
                grid.columns[i].width += 20;
            }


            grid.columns[grid.columns.length - 1].width = 200;


        },

        retrieveReporte(ruta) {
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(ruta, "_blank");
            } else {
                helpers.openNewBackgroundTab(ruta);
            }
            setTimeout(() => {
                this.$store.commit("setOverlay", false);
            }, 1000);
        },
        DescargarReporte(itemId) {
            var jsonData = {
                id: itemId,
                token: this.$store.state.token
            };

            service
                .api()
                .RetrieveOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.retrieveReporte(response.payload);
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        EliminarReporte() {
            var jsonData = {
                id: this.eliminarId,
                token: this.$store.state.token,
                reportType: 'REPORT-CFDI-RELACIONADOS'
            };
            this.showDialogEliminarReport = false;
            service
                .api()
                .DeleteOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.alert_okIn = true;
                        this.okMessageIn = "Reporte Eliminado Correctamente";
                        setTimeout(() => {
                            this.alert_okIn = false;
                            this.okMessageIn = "";
                        }, 10000);
                        if (this.itemsGrandes.length == 1)
                            this.itemsGrandes = [];
                        else
                            this.ObtenerReportes();
                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                });
        },
        requestItems() {
            this.showDialogCrear = false;
            this.showDialogReports = false;
            var jsonData = {
                reportType: 'REPORT-CFDI-RELACIONADOS',
                name: this.nombreIn,
                description: this.descripcionIn,
                commands: JSON.stringify({ dateInit: this.selectedDatesIn[0], dateEnd: this.selectedDatesIn[1], context: this.contextoIn.substr(0, 1), docType: this.getTipoDocIn(), rfc: this.rfcSearch, relacion: this.relacionesIn }),
                token: this.$store.state.token,
            };

            service
                .api()
                .RequestOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.alert_okIn = true;
                        this.okMessageIn = "Reporte Solicitado Correctamente";
                        setTimeout(() => {
                            this.alert_okIn = false;
                            this.okMessageIn = "";
                        }, 10000);

                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                    this.descripcionIn = "";
                });


            this.nombreIn = "";
            this.descripcionIn = "";
            this.selectedDatesIn = [];
            this.$refs.dateRange.limpiarFechas();
            this.contextoIn = "Emitidos";
            this.tipoDocIn = "Seleccionar Todos",
                this.rfcSearch = "";
                this.relacionesIn = "";
            this.showDialogReports = true;


        },
        ObtenerReportes() {
            this.showDialogReports = true;
            var jsonData = {
                token: this.$store.state.token,
                reportType: 'REPORT-CFDI-RELACIONADOS'
            };

            service
                .api()
                .RetrieveOnDemandReports(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.itemsGrandes = JSON.parse(response.payload);
                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;

                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                });
        },
        ConfirmarEliminarReporte(item) {
            this.eliminarId = item.id;
            this.itemDesc = item.description;
            this.showDialogEliminarReport = true;
        },
        cambioOrigen() {

            this.items = [];
        },
        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contexto.substring(0, 1),
                uuid: item.id,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (item.tipo === "R")
                par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
            else par = item.tipo + "|" + item.pac + "|";
            let parameters = par + "|" + item.id + "|" + item.fecha_emision;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");
            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 3000);
        },

        openDialog() {
            this.$refs.dialog.showDialogGuid = true;
        },
        verMasDatos(item) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: item.id,
            };
            service
                .api()
                .SearchByGUID(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemFind = payload;
                        this.openDialog();
                    } else {

                        this.alert_errorR = true;
                        this.errorMessageR = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorR = false;
                            this.errorMessageR = "";
                        }, 10000);
                    }
                });

        },
        start() {
            this.offest = 0;
            this.goPage = this.noPagina = 1;
            this.limit = this.pageSize;
            this.getReporteItems();
        },
        previous() {
            if (this.noPagina === 1) return;

            this.limit = this.pageSize;
            this.noPagina--;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;
            this.getReporteItems();
        },
        next() {
            if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
                return;

            this.limit = this.pageSize;
            if (this.noPagina + 1 === this.totalPaginas) {
                let res = this.totalDocumentos % this.pageSize;
                if (res !== 0) this.limit = res;
            }
            this.noPagina++;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;

            this.getReporteItems();
        },
        end() {
            this.limit = this.pageSize;
            let res = this.totalDocumentos % this.pageSize;
            if (res !== 0) this.limit = res;

            this.goPage = this.noPagina = this.totalPaginas;
            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
        },
        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                this.getReporteItems();
            else this.noPagina = this.goPage = actualPage;
        },
        changePagination() {
            this.getReport();
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        currencyFormat6(value) {
            return helpers.formatMoney(value, 6);
        },
        tipoRelacionFormat(value) {
            return helpers.TipoRelacion(value);
        },
        handleDate() {
            this.limit = this.pageSize;
            this.offest = 0;
            this.noPagina = 1;
            this.$refs.menu.save(this.selectedDates[0]);
            this.getReporteItems();
        },
        getReport() {
            this.goPage = 1;
            this.offest = 0;
            this.noPagina = 1;
            this.limit = this.pageSize;
            this.$store.commit("setAlertError", false);
            this.errorMessage = "";
            this.getTotalCount();
            if (this.cambio) {
                this.cambio = false;
            }
        },
        getReporteItems() {
            this.items = [];
            this.selectedRows = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substring(0, 1),
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
                dateTs: this.dateTs,
                relacion: this.relaciones
            };
            service
                .api()
                .RelacionadosList(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.items = payload;

                        this.doctosPagina = this.items.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCount() {
            this.items = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                context: this.contexto.substring(0, 1),
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
                dateTs: this.dateTs,
                relacion: this.relaciones,
            };

            service
                .api()
                .RelacionadosCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItems();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getReporteConciliatorioCSV() {
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substring(0, 1),
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
                dateTs: this.dateTs,
                relacion: this.relaciones
            };
            service
                .api()
                .RelacionadosCSV(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.csvData = JSON.parse(response.payload);
                        JSONToCSV(
                            this.csvData,
                            "Reporte_Relacionados_" + helpers.dateNowCSV(),
                            true
                        );
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
                    }
                });
        },

        ConsultarDocsRelacionados(item) {

            let uuids = [];

            if (item.cfdi_relacionados) {

                item.cfdi_relacionados.forEach(function (element) {
                    uuids = [...uuids, ...element.uuids];
                });
            }
            else {
                uuids = item.uuids;
            }
            var jsonData = {
                token: this.$store.state.token,
                parentDate: item.fecha_emision.substr(0, 10),
                uuids: uuids,
            };

            service
                .api()
                .RelacionadosDocs(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.showDialogRelacionados = true;
                        this.itemSelected = item;
                        this.itemsRel = JSON.parse(response.payload);
                        if (response.payload == "[]") {
                            this.showDialogRelacionados = false;

                            this.$store.commit("setAlertOk", false);
                            this.$store.commit("setAlertError", true);
                            this.$store.commit("setAlertMessage", "El o los documentos relacionados a los que se hacen referencia no se encuentran en la base de datos");
                        }
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        ConsultarEstadoDoc(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: item.tipo,
                uuid: item.id,
                issuedDate: item.fecha_emision,
                issuer: item.emisor_rfc,
                receiver: item.receptor_rfc,
                total: item.total,
            };

            service
                .api()
                .Consult(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.selectedStatus = JSON.parse(response.payload);
                        this.itemSelected = item;
                        this.showDialogStatus = true;
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        esOrdenamiento(columna) {
            return this.currentOrderColumn === columna;
        },
        cerrarStatus() {
            this.showDialogStatus = false;
            if (this.selectedStatus.estado == "Cancelado") this.getReport();
        },
        sortColumn(columnName) {
            if (this.currentOrderColumn === columnName) {
                if (this.currentOrder === "DESC") {
                    this.currentOrder = "ASC";
                } else {
                    this.currentOrder = "DESC";
                }
            }
            this.currentOrderColumn = columnName;
            this.getReporteItems();

            this.rfcRClass = false;
            this.serieClass = false;
            this.fechaEClass = false;
            this.rfcEClass = false;
            this.folioClass = false;
            this.monedaClass = false;
            this.tipo_comprobanteClass = false;
            this.metodo_pagoClass = false;

            switch (columnName) {
                case "receptor.rfc":
                    this.rfcRClass = true;
                    break;
                case "serie":
                    this.serieClass = true;
                    break;
                case "nfecha_emision":
                    this.fechaEClass = true;
                    break;
                case "emisor.rfc":
                    this.rfcEClass = true;
                    break;
                case "folio":
                    this.folioClass = true;
                    break;
                case "moneda":
                    this.monedaClass = true;
                    break;
                case "tipo_comprobante":
                    this.tipo_comprobanteClass = true;
                    break;
                case "metodo_pago":
                    this.metodo_pagoClass = true;
                    break;
            }
        },

        getTipoDoc() {
            let firstLetter = this.tipoDoc.substr(0, 1);
            return firstLetter === "S" ? "" : firstLetter;
        },
        getTipoDocIn() {
            let firstLetter = this.tipoDocIn.substr(0, 1);
            return firstLetter === "S" ? "" : firstLetter;
        },
        getTipoComprobante(tipoDoc) {
            let tipo = "";

            switch (tipoDoc) {
                case "I":
                    tipo = "Ingreso";
                    break;
                case "E":
                    tipo = "Egreso";
                    break;
                case "T":
                    tipo = "Traslado";
                    break;
                case "P":
                    tipo = "Pago";
                    break;
            }
            return tipo;
        },
        parseDocType(tipoDoc) {

            return helpers.parseDoctype(tipoDoc);

        }
    },
    computed: {

        itemsRelaciones() {
            let itemsReg = helpers.TipoRelaciones;
            let nuevoObjeto = { codigo: "", name: "Seleccionar Todos" };
            itemsReg.unshift(nuevoObjeto);
            return itemsReg;
        },
        hasNext() {
            return this.noPagina !== this.totalPaginas;
        },
        hasPrevious() {
            return this.noPagina !== 1;
        },
        esEmitido() {
            return this.contexto.substring(0, 1) === "E";
        },
        esRecibido() {
            return this.contexto.substring(0, 1) === "R";
        },

        showSearchButton() {
            return this.selectedDates.length > 1;
        },
        showSearchButtonGUID() {
            return this.guidSearch.length > 0;
        },
        labelRfc() {
            if (this.contexto.substring(0, 1) === "E") return "RFC Receptor";
            else return "RFC Emisor";
        },
        fechaInicial() {
            return helpers.getFechaLimite(false);
        },
        allowIssued() {
            return this.$store.state.allowIssued === true;
        },
        allowReceived() {
            return this.$store.state.allowReceived === true;
        },
        itemsOrigen() {
            let elements = [];
            if (this.allowIssued)
                elements.push("Emitidos");
            if (this.allowReceived)
                elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        },
        showCreateButton() {
            return this.selectedDatesIn.length > 0 && this.descripcionIn.length > 0;
        },
    },
};
</script>

<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoGuia {
    width: 50px;
}


.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}

.paddingCard {
    padding-right: 25px;
    padding-left: 15px;
}


.min-width-control {
    min-width: 160px;
}

.min-width-control-xl {
    min-width: 200px;
}


.wj-flexgrid {
    max-height: 600px;
    margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
    background: rgb(255, 255, 125);
}

.borde th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    background-color: #113145;
    color: white;
}
</style>
