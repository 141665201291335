<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">

        <v-dialog v-model="showAdminTagsGroup" persistent min-width="800px" max-width="800px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showAdminTagsGroup = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Administrador de Etiquetas Multiple</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsTags" :key="item.id" class="altura">

                      <td class="pa-0 md-0">
                        <v-tooltip left v-if="selectedTags.includes(item.Color)">
                          <template v-slot:activator="{ on }">
                            <v-btn @click="DeleteEtiqueta(item.Color)" icon :color="item.Color" v-on="on">
                              <v-icon>mdi-minus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar Etiqueta</span>
                        </v-tooltip>
                        <v-tooltip left v-else>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="AddEtiqueta(item.Color)" icon :color="item.Color" v-on="on">
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Agregar Etiqueta</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{ item.Description }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="ActualizarTagsGroup" text large>Actualizar Etiquetas</v-btn>
              <v-btn color="danger" text @click="showAdminTagsGroup = false">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="showSelectTags" persistent min-width="800px" max-width="800px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showSelectTags = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Seleccionar Etiquetas</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in findTags" :key="item.id" class="altura">
                      <td>
                        <v-checkbox v-model="item.check" :color="item.Color"></v-checkbox>
                      </td>
                      <td>
                        <v-icon :color="item.Color">mdi-circle</v-icon>
                      </td>
                      <td>
                        {{ item.Description }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showAdminTags" persistent min-width="800px" max-width="800px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showAdminTags = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Administrador de Etiquetas de {{ itemSelected.id }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsTags" :key="item.id" class="altura">

                      <td class="pa-0 md-0">
                        <v-tooltip left v-if="selectedTags.includes(item.Color)">
                          <template v-slot:activator="{ on }">
                            <v-btn @click="DeleteEtiqueta(item.Color)" icon :color="item.Color" v-on="on">
                              <v-icon>mdi-minus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar Etiqueta</span>
                        </v-tooltip>
                        <v-tooltip left v-else>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="AddEtiqueta(item.Color)" icon :color="item.Color" v-on="on">
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Agregar Etiqueta</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{ item.Description }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="ActualizarTags" text large>Actualizar</v-btn>
              <v-btn color="danger" text @click="showAdminTags = false">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialogNotas" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogNotas = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Notas del Documento : {{ itemSelected.serie }} -
                {{ itemSelected.folio }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col md="12" justify="center">
                  <v-textarea solo v-model="notaText" maxlength="160"></v-textarea>
                  <h5>Hasta 160 caracteres</h5>
                  <h4 v-if="itemSelected.datetime_nota">Última Actualización:
                    {{ itemSelected.datetime_nota }}.<br /> Usuario: {{ itemSelected.usr_nota }}</h4>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="actualizarNota" text large>{{ mensajeItem }}
                Nota</v-btn>
              <v-btn color="danger" text @click="showDialogNotas = false">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCrear = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" justify="center">
                  <date-range-picker v-model="selectedDatesIn" :disabled="dateTs.length > 0"></date-range-picker>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="rfcSearchIn" counter="0" label="RFC" class="my-1" maxlength="13">
                  </v-text-field>
                </v-col>
                <v-col md="8" justify="center">
                  <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar Reporte
              </v-btn>
              <v-btn color="danger" text @click="showDialogCrear = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Reporte:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este reporte sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogReports" persistent>
          <v-col cols="12">
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogReports = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="ObtenerReportes" icon v-on="on">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Tabla</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn @click="showDialogCrear = true" large color="second">
                  <v-icon>mdi-plus</v-icon>Solicitar Reporte
                </v-btn>
              </v-toolbar>

              <v-row>
                <v-col cols="12">
                  <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{ errorMessageIn }}
                  </v-alert>
                  <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{ okMessageIn }}</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Descripción</th>
                          <th>Fecha Solicitud</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th>RFC</th>
                          <th>Mensaje</th>
                          <th>Resultado</th>

                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                          <td>
                            {{ item.description }}
                          </td>
                          <td>
                            {{ item.requestDate }}
                          </td>
                          <td>
                            {{ item.commands.dateInit }}
                          </td>
                          <td>
                            {{ item.commands.dateEnd }}
                          </td>
                          <td>
                            {{ item.commands.taxid }}
                          </td>
                          <td>
                            {{ item.message }}
                          </td>
                          <td>
                            {{
                              item.ready
                              ? item.result
                                ? "Exitoso"
                                : "Fracasado"
                              : "Procesando"
                            }}
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready && item.result">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar Reporte</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Reporte</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-dialog>


        <v-dialog v-model="showDialogStatus" persistent max-width="700px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogStatus = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Detalle documento : {{ itemSelected.serie }} -
                {{ itemSelected.folio }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Estado</th>
                      <th>Cancelable</th>
                      <th>Estado Cancelación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ selectedStatus.estado }}</td>
                      <td>{{ selectedStatus.cancelable }}</td>
                      <td>{{ selectedStatus.estado_cancelacion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialogCom" persistent max-width="1000px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCom = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Complemento del documento:
                {{ itemSelectedCom.id }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-card class="my-3 pa-3">
                <div class="body-1">
                  Razón Social: {{ itemSelectedCom.nombre }}
                </div>
                <div class="body-1">RFC: {{ itemSelectedCom.rfc }}</div>
                <div class="body-1">CURP: {{ itemSelectedCom.curp }}</div>
                <div class="body-1">
                  Tipo Regimen: {{ itemSelectedCom.tipo_regimen }}
                </div>
                <div class="body-1">
                  Tipo Nomina: {{ itemSelectedCom.tipo_nomina }}
                </div>
              </v-card>
              <br />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Fecha Emisión</th>
                      <th>Fecha Timbre</th>
                      <th>Fecha Pago</th>
                      <th>Fecha Inicio Pago</th>
                      <th>Fecha Final Pago</th>
                      <th>Dias Pagados</th>
                      <th>Periodicidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ itemSelectedCom.fecha_emision }}</td>
                      <td>{{ itemSelectedCom.fecha_timbre }}</td>
                      <td>{{ itemSelectedCom.fecha_pago }}</td>
                      <td>{{ itemSelectedCom.fecha_inicio_pago }}</td>
                      <td>{{ itemSelectedCom.fecha_final_pago }}</td>
                      <td>{{ itemSelectedCom.numero_dias_pagados }}</td>
                      <td>{{ itemSelectedCom.periodicidad_pago }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <p class="font-weight-black">Totales:</p>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-right">Sueldos</th>
                      <th class="text-right">Gravado</th>
                      <th class="text-right">Exento</th>
                      <th class="text-right">Otras Deducciones</th>
                      <th class="text-right">Impuestos Retenidos</th>
                      <th class="text-right">Percepciones</th>
                      <th class="text-right">Deducciones</th>
                      <th class="text-right">Otros Pagos</th>
                      <th class="text-right">Separación Indemnización</th>
                      <th class="text-right">Jubilación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td align="right">
                        {{ currencyFormat(itemSelectedCom.total_sueldos) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelectedCom.total_gravado) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelectedCom.total_exento) }}
                      </td>
                      <td align="right">
                        {{
                          currencyFormat(
                            itemSelectedCom.total_otras_deducciones
                          )
                        }}
                      </td>
                      <td align="right">
                        {{
                          currencyFormat(
                            itemSelectedCom.total_impuestos_retenidos
                          )
                        }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelectedCom.total_percepciones) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelectedCom.total_deducciones) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelectedCom.total_otros_pagos) }}
                      </td>
                      <td align="right">
                        {{
                          currencyFormat(
                            itemSelectedCom.total_separacion_indemnizacion
                          )
                        }}
                      </td>
                      <td align="right">
                        {{
                          currencyFormat(
                            itemSelectedCom.total_jubilacion_pension_retiro
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialogCom = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>

      <v-row justify="center">
        <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>Reporte CFDIs Nómina

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/reporte-cfdi-nomina" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="4" class="px-2 mx-md-10">
                <date-range-picker v-model="selectedDates" :disabled="dateTs.length > 0"></date-range-picker>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field class="min-width-control-xl" v-model="rfcSearch" counter="0" label="RFC"
                  maxlength="13"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsNotas" v-model="tipoNotas" label="Notas" outlined
                  :disabled="dateTs.length > 0"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">

                <v-btn color="primary" white large v-on:click="showSelectTags = true">Etiquetas
                </v-btn>
                <v-icon v-for="item in findTags" :key="item.name" v-show="item.check"
                  :color="item.Color">mdi-circle</v-icon>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar Listado
                </v-btn>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                    <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">Reportes Bajo Demanda
                    </v-btn>
                  </template>
                  <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title>
            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon v-show="mostrarAgignarEtiquetas" color="primary" class="ma-2 white--text"
                  @click="AsignarEtiquetas" v-on="on" large>
                  mdi-tag-plus</v-icon>
              </template>
              <span>Asignar Etiquetas</span>
            </v-tooltip>
            <v-spacer class="d-none d-sm-flex"></v-spacer>
            <v-icon class="d-none d-sm-flex" @click="getReporteNominaCSV" :disabled="totalDocumentos > 5000"
              color="second">mdi-file-export</v-icon>
            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large color="second">
                    mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                    mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>

            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">

              <wj-flex-grid-column :header="' '" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell">

                  <v-simple-table v-if="cell.item.tags" class="ma-2">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <tr @click="AgregarEtiqueta(cell.item, cell.row.index)" v-on="on">
                          <td v-for="itemTag in cell.item.tags.split(',')" :key="itemTag.id">
                            <v-icon x-small :color="itemTag">
                              mdi-checkbox-blank-circle
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                      <span>Actualizar Etiqueta(s)</span>
                    </v-tooltip>
                  </v-simple-table>
                  <v-tooltip v-else left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="AgregarEtiqueta(cell.item, cell.row.index)" icon color="green" v-on="on">
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar Etiqueta(s)</span>
                  </v-tooltip>



                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column  :header="'RFC'" :binding="'receptor.rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column  :header="'Razón Social'" :binding="'receptor.nombre'" width="4*"
                :isReadOnly="true" />
              
              <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*" :isReadOnly="true" />

              
              <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-eye-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Más</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="ConsultarEstadoDoc(cell.item)" icon color="orange" v-on="on">
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Consultar Estado del Documento</span>
                  </v-tooltip>
                  <v-tooltip left v-if="cell.item.nota">
                    <template v-slot:activator="{ on }">
                      <v-btn @click="upsertNota(cell.item, cell.row.index)" icon color="purple" v-on="on">
                        <v-icon>mdi-note-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar Nota</span>
                  </v-tooltip>
                  <v-tooltip left v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="upsertNota(cell.item, cell.row.index)" icon color="green" v-on="on">
                        <v-icon small>mdi-note-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Crear Nota</span>
                  </v-tooltip>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>


          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';


import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
import Dialogo from '../Componentes/DialogoBuscar.vue';

export default {
  name: "ReporteNomina",

  components: {
    DateRangePicker,
    Dialogo
  },
  data: () => ({
    selectedItems: [],
    itemFind: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },

    selectedDates: [],
    selectedDatesIn: [],
    allCheckBox: false,
    batchUuids: [],
    checkboxes: [],
    checkboxAll: false,
    showAdminTagsGroup: false,
    selectedRows: [],
    selectedIndex: 0,
    showDialogEliminarReport: false,
    showDialogReports: false,
    showDialogCrear: false,
    errorMessageIn: "",
    okMessageIn: "",
    alert_errorIn: false,
    alert_okIn: false,
    itemsGrandes: [],
    descripcionIn: "",
    itemDesc: "",
    showDialogNotas: false,
    notaText: "",
    showDialog: false,
    showDialogCom: false,
    showDialogStatus: false,
    goPage: 1,
    folioDoc: "",
    modal: false,




    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200", "500"],
    items: [],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    folioClass: false,
    itemSelected: {
      descuento: 0,
      emisor: {
        nombre: "",
        regimen: "",
        rfc: "",
      },
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      id: "",
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      receptor: {
        nombre: "",
        residencia: "",
        rfc: "",
        usocfdi: "",
      },
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
    },
    itemSelectedCom: {
      rfc: "",
      nombre: "",
      id: "",
      numero_empleado: "",
      curp: "",
      tipo_regimen: "",
      tipo_nomina: "",
      fecha_pago: "",
      fecha_inicio_pago: "",
      fecha_final_pago: "",
      numero_dias_pagados: "",
      periodicidad_pago: "",
      fecha_emision: "",
      total_sueldos: "",
      total_gravado: "",
      total_exento: "",
      total_otras_deducciones: "",
      total_impuestos_retenidos: "",
      total_percepciones: "",
      total_deducciones: "",
      total_otros_pagos: "",
      total_separacion_indemnizacion: "",
      total_jubilacion_pension_retiro: "",
      fecha_timbre: "",
    },
    rfcSearch: "",
    dateTs: "",
    rfcSearchIn: "",
    itemsTags: [],
    showAdminTags: false,
    selectedTags: [],
    showSelectTags: false,
    findTags: [],
    itemsNotas: ["Sin Importancia", "Con Notas", "Sin Notas"],
    tipoNotas: "Sin Importancia"
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.selectTagsCatalog();

    this.$store.commit("setAlertError", false);
    this.errorMessage = "";

    let dataInterval = this.$store.state.dateInterval;

    if (dataInterval === null) return;

    this.selectedDates[0] = dataInterval.dateInit;
    this.selectedDates[1] = dataInterval.dateEnd;
    this.dateTs = dataInterval.dateTs;
    this.$store.commit("setDateInterval", null);

    this.getTotalCount();
  },
  methods: {
    initGrid: function (grid) {

      for (let i = 1; i < grid.columns.length - 1; i++) {
        grid.autoSizeColumn(i);
      }

      grid.columns[0].width = 100;
      grid.columns[1].width = 100;
      grid.columns[2].width = 100;
     
      grid.columns[7].width = 150;
      grid.columns[8].width = 150;
      grid.columns[9].width = 150;
     


      grid.columns[grid.columns.length - 1].width = 200;

      this.selector = new Selector(grid, {
        itemChecked: () => {
          this.selectedItems = grid.rows.filter(r => r.isSelected);
        }
      });
    },
    openDialog() {
      this.$refs.dialog.showDialogGuid = true;
    },
    verMasDatos(item) {
      var jsonData = {
        token: this.$store.state.token,
        uuid: item,
      };
      service
        .api()
        .SearchByGUID(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemFind = payload;
            this.openDialog();
          } else {

            this.alert_errorR = true;
            this.errorMessageR = response.errorMessage;
            setTimeout(() => {
              this.alert_errorR = false;
              this.errorMessageR = "";
            }, 10000);
          }
        });

    },

  
    AsignarEtiquetas() {
      this.manageTagsGroup();

    },

    manageTagsGroup() {


      var jsonData = {
        token: this.$store.state.token,
      };

      service
        .api()
        .TagCatalog(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemsTags = payload;
            this.$store.commit("setOverlay", false);
            this.selectedTags = [];
            this.showAdminTagsGroup = true;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },



   
    selectTagsCatalog() {

      var jsonData = {
        token: this.$store.state.token,
      };
      this.findTags = [];
      service
        .api()
        .TagCatalog(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            let itemsIn = payload;
            let coleccion = [];
            itemsIn.forEach(function (element) {
              let tagIn = { check: false, Color: element.Color, Description: element.Description };
              coleccion.push(tagIn);
            });
            this.findTags = coleccion;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    rowClicked(row) {
      this.selectedRows = [];
      this.toggleSelection(row.id);
      console.log(row);
    },
    toggleSelection(keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows.push(keyID);
      }
    },
    upsertNota(item, index) {
      this.itemSelected = item;
      this.selectedIndex = index;
      this.notaText = "";
      if (item.hasOwnProperty('nota'))
        this.notaText = this.itemSelected.nota;

      this.showDialogNotas = true;
    },
    actualizarNota() {
      var jsonData = {
        token: this.$store.state.token,
        particion: this.itemSelected.particion,
        uuid: this.itemSelected.id,
        nota: this.notaText,
      };

      service
        .api()
        .UpsertNote(jsonData)
        .then((response) => {
          if (response.result) {
            //this.getReport();
            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Nota Actualizada");


            if (this.notaText === '') {
              this.itemSelected.nota = null;
              this.itemSelected.datetime_nota = null;
              this.itemSelected.usr_nota = null;
            }
            else {
              this.itemSelected.nota = this.notaText;

              let fechaHoy = new Date()
              this.itemSelected.datetime_nota = fechaHoy.toISOString().substring(0, 10) + " " + fechaHoy.toLocaleTimeString('es-MX');
              this.itemSelected.usr_nota = this.$store.state.usuario;
            }



            this.items[this.selectedIndex] = this.itemSelected;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
          this.notaText = "";
          this.showDialogNotas = false;
        });

    },
    ConsultarEstadoDoc(item) {

      let data = item.particion.split('-');

      var jsonData = {
        token: this.$store.state.token,
        context: "E",
        uuid: item.id,
        issuedDate: item.fecha_emision,
        issuer: data[0],
        receiver: item.receptor.rfc,
        total: item.total,
      };

      service
        .api()
        .Consult(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.selectedStatus = JSON.parse(response.payload);
            this.itemSelected = item;
            this.showDialogStatus = true;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    verComplemento(item) {
      var jsonData = {
        token: this.$store.state.token,
        id: item.id,
        issuedDate: item.fecha_emision.substr(0, 10),
      };
      service
        .api()
        .NominaDetalleFile(jsonData)
        .then((response) => {
          if (response.result) {
            this.itemSelectedCom = JSON.parse(response.payload);
            this.showDialogCom = true;
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: "E",
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R")
        par = item.tipo + "|" + item.pac + "|" + item.emisor.rfc;
      else par = item.tipo + "|" + item.pac + "|";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision + "|N";
      let urlPDF = "";
      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.$store.commit("setOverlay", false);
      }, 2000);
    },
    exportExcel() {
      var headers = {
        RfcE: "RFC Emisor",
        NombreE: "Razon Social Emisor",
        CRegimen: "Código Regimen",
        NRegimen: "Regimen",
        UUID: "Folio Fiscal",
        RfcR: "RFC Receptor",
        NombreR: "Razon Social Receptor",
        ResidenciaR: "Residencia Receptor",
        CUsoCfdiR: "Código Uso CFDI",
        NUsoCfdiR: "Uso CFDI",
        Tipo: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        // FechaCancelacion: "Fecha Cancelación",
        FechaEmision: "Fecha Emisión",
        FechaTimbre: "Fecha Timbre",
        Serie: "Serie",
        Folio: "Folio",
        LugarExpedicion: "Lugar de Expedicion",
        MetodoPago: "Método Pago",
        CFormaPago: "Código Forma de Pago",
        NFormaPago: "Forma de Pago",
        Moneda: "Moneda",
        TipoCambio: "Tipo Cambio",
        SubTotal: "Subtotal",
        Descuento: "Descuento",
        TotalTraslados: "Total Traslados",
        TotalRetenciones: "Total Retenciones",
        Total: "Total",
        PAC: "PAC",
      };
      var itemsFormatted = [];
      this.csvData.forEach((item) => {
        itemsFormatted.push({
          RfcE: helpers.ConvertToEmpty(item.emisor.rfc),
          NombreE: helpers.ConvertToEmpty(item.emisor.nombre),
          CRegimen: helpers.ConvertToEmpty(item.emisor.regimen),
          NRegimen: helpers
            .RegimenFiscal(helpers.ConvertToEmpty(item.emisor.regimen))
            .replace(/,/g, " "),
          UUID: helpers.ConvertToEmpty(item.id),
          RfcR: helpers.ConvertToEmpty(item.receptor.rfc),
          NombreR: helpers.ConvertToEmpty(item.receptor.nombre),
          ResidenciaR: helpers.ConvertToEmpty(item.receptor.residencia),
          CUsoCfdiR: helpers.ConvertToEmpty(item.receptor.usocfdi),
          NUsoCfdiR: helpers
            .UsoCFDI(helpers.ConvertToEmpty(item.receptor.usocfdi))
            .replace(/,/g, " "),
          Tipo: "Emitido",
          TipoComprobante: "Nomina",
          // FechaCancelacion: helpers.ConvertToEmpty(item.fecha_cancelacion),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
          Serie: helpers.ConvertToEmpty(item.serie),
          Folio: helpers.ConvertToEmpty(item.folio),
          LugarExpedicion: helpers.ConvertToEmpty(item.lugar_expedicion),
          MetodoPago: helpers.ConvertToEmpty(item.metodo_pago),
          CFormaPago: helpers.ConvertToEmpty(item.forma_pago),
          NFormaPago: helpers
            .FormaPago(helpers.ConvertToEmpty(item.forma_pago))
            .replace(/,/g, " "),
          Moneda: helpers.ConvertToEmpty(item.moneda),
          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio),
          SubTotal: helpers.ConvertToEmpty(item.subtotal),
          Descuento: helpers.ConvertToEmpty(item.descuento),
          TotalTraslados: helpers.ConvertToEmpty(item.total_traslados),
          TotalRetenciones: helpers.ConvertToEmpty(item.total_retenciones),
          Total: helpers.ConvertToEmpty(item.total),
          PAC: helpers.ConvertToEmpty(item.pac),
        });
      });

      let datos = {
        "RFC Receptor": helpers.ConvertToNoAplica(this.rfcSearch),
        "Fecha Final": this.selectedDates[1],
        "Fecha Inicio": this.selectedDates[0],
        "Fecha de Solicitud": helpers.FechaActual(),
        "Reporte": "Listado Conciliatorio Nomina"
      };

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Nomina_CFDI_" + helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFileV1(headers, itemsFormatted, fileTitle, datos);
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.noPagina = 1;
      this.limit = this.pageSize;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      this.checkboxes = [];
      this.selectedRows = [];
      this.items = [];
      let tagsInterno = [];
      this.findTags.forEach(function (element) {
        if (element.check)
          tagsInterno.push(element.Color);
      });

      let noteInterno = "";

      switch (this.tipoNotas.substr(0, 5)) {
        case "Con N":
          noteInterno = "1";
          break;
        case "Sin N":
          noteInterno = "0";
          break;
        default:
          noteInterno = "";
      }
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
        dateTs: this.dateTs,
        tags: tagsInterno.toString(),
        note: noteInterno
      };
      let j;
      for (j = 0; j < this.items.length; j++)
        this.checkboxes[j] = false;

      service
        .api()
        .NominaLista(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      let tagsInterno = [];
      this.findTags.forEach(function (element) {
        if (element.check)
          tagsInterno.push(element.Color);
      });

      let noteInterno = "";

      switch (this.tipoNotas.substr(0, 5)) {
        case "Con N":
          noteInterno = "1";
          break;
        case "Sin N":
          noteInterno = "0";
          break;
        default:
          noteInterno = "";
      }
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        rfc: this.rfcSearch,
        dateTs: this.dateTs,
        tags: tagsInterno.toString(),
        note: noteInterno
      };

      service
        .api()
        .NominaCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteNominaCSV() {
      let tagsInterno = [];
      this.findTags.forEach(function (element) {
        if (element.check)
          tagsInterno.push(element.Color);
      });

      let noteInterno = "";

      switch (this.tipoNotas.substr(0, 5)) {
        case "Con N":
          noteInterno = "1";
          break;
        case "Sin N":
          noteInterno = "0";
          break;
        default:
          noteInterno = "";
      }
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        rfc: this.rfcSearch,
        dateTs: this.dateTs,
        tags: tagsInterno.toString(),
        note: noteInterno
      };

      service
        .api()
        .NominaCSV(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
          }
        });
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.folioClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
      }
    },

    requestItems() {
      this.showDialogCrear = false;

      var jsonData = {
        description: this.descripcionIn,
        dateInit: this.selectedDatesIn[0],
        dateEnd: this.selectedDatesIn[1],
        taxid: this.rfcSearchIn,
        token: this.$store.state.token,
        reportType: 'NOMINA'
      };

      this.showDialogReports = false;
      service
        .api()
        .RequestNominaReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Solicitado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
          this.descripcionIn = '';
          this.selectedDatesIn[0] = '';
          this.selectedDatesIn[1] = '';
          this.rfcSearchIn = '';
          this.showDialogReports = true;
        });


    },
    ObtenerReportes() {
      this.showDialogReports = true;
      var jsonData = {
        token: this.$store.state.token,
        reportType: 'NOMINA'
      };

      service
        .api()
        .RetrieveNominaReports(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsGrandes = JSON.parse(response.payload);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;

            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
        reportType: 'NOMINA'
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .DeleteNominaReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Eliminado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
            if (this.itemsGrandes.length == 1)
              this.itemsGrandes = [];
            else
              this.ObtenerReportes();
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    DescargarReporte(itemId) {
      var jsonData = {
        id: itemId,
        token: this.$store.state.token,
        reportType: 'NOMINA'
      };

      service
        .api()
        .RetrieveNominaReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.retrieveReporte(response.payload);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },
    manageTags() {


      var jsonData = {
        token: this.$store.state.token,
      };

      service
        .api()
        .TagCatalog(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemsTags = payload;
            this.$store.commit("setOverlay", false);
            this.showAdminTags = true;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    AgregarEtiqueta(item, index) {
      this.itemSelected = item;
      this.selectedIndex = index;
      if (item.tags) {
        this.selectedTags = item.tags.split(',');
      }
      else {
        this.selectedTags = [];
      }

      this.manageTags();
    },
    AddEtiqueta(color) {
      this.selectedTags.push(color);
    }
    ,
    DeleteEtiqueta(color) {

      const index = this.selectedTags.indexOf(color);
      this.selectedTags.splice(index, 1);

    },
    ActualizarTags() {
      var jsonData = {
        token: this.$store.state.token,
        particion: this.itemSelected.particion,
        uuid: this.itemSelected.id,
        tags: this.selectedTags.sort().toString(),
      };

      service
        .api()
        .TagUpsert(jsonData)
        .then((response) => {
          if (response.result) {

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Etiquetas Actualizadas");
            this.items[this.selectedIndex].tags = this.selectedTags.toString();

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
      this.showAdminTags = false;
    },
    ActualizarTagsGroup() {
      let uuidsInterno = this.selectedItems.map(({ _data }) => _data.id);

      var jsonData = {
        token: this.$store.state.token,
        particion: this.items[0].particion,
        uuids: uuidsInterno.toString(),
        tags: this.selectedTags.sort().toString(),
      };

      service
        .api()
        .TagUpsertGroup(jsonData)
        .then((response) => {
          if (response.result) {

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", response.payload);

            let i;
            for (i = 0; i < this.selectedItems.length; i++) {
              this.items[this.selectedItems[i]._idx].tags = this.selectedTags.toString();
              //  this.checkboxes[i] = false;

            }
            this.showAdminTagsGroup = false;
            this.getReporteItems();

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
      this.showAdminTags = false;
    }

  },
  computed: {
    mostrarAgignarEtiquetas() {
      return this.selectedItems.length > 0;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    showSearchButton() {
      return this.selectedDates.length > 1;
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    showCreateButton() {
      return this.selectedDatesIn.length > 1 && this.descripcionIn.length > 0;
    },
    showNotasButton() {
      return this.notaText.length > 0;
    },
    mensajeItem() {
      return this.itemSelected.nota == null ? "Guardar" : "Actualizar";
    }
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}


.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}

.wj-control{
        font-family: 'Calibri' !important;
    }
</style>
