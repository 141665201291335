<template>
    <div class="py-10">
        <v-container fluid class="px-10">
            <v-row justify="center">
                <v-dialog v-model="showDialogStatus" persistent max-width="700px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="cerrarStatus">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Detalle documento : {{ itemSelected.serie }} - {{ itemSelected.folio }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table class="ma-3">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Estado</th>
                                            <th>Cancelable</th>
                                            <th>Estado Cancelación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="altura">
                                            <td>{{ selectedStatus.estado }}</td>
                                            <td>{{ selectedStatus.cancelable }}</td>
                                            <td>{{ selectedStatus.estado_cancelacion }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-dialog>


                <v-dialog v-model="showDialogReports" persistent>
                    <v-col cols="12">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="showDialogReports = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click="ObtenerReportes" icon v-on="on">
                                            <v-icon>mdi-cached</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refrescar Tabla</span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-btn @click="showDialogCrear = true" large color="second">
                                    <v-icon>mdi-plus</v-icon>Solicitar Reporte
                                </v-btn>
                            </v-toolbar>

                            <v-row>
                                <v-col cols="12">
                                    <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{
                                        errorMessageIn }}
                                    </v-alert>
                                    <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{ okMessageIn
                                    }}</v-alert>
                                </v-col>
                                <v-col cols="12">
                                    <v-simple-table height="600" fixed-header dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Descripción</th>
                                                    <th>Fecha Solicitud</th>
                                                    <th>Contexto</th>
                                                    <th>Tipo Comprobante</th>
                                                    <th>Fecha Inicial</th>
                                                    <th>Fecha Final</th>
                                                    <th>RFC</th>
                                                    <th>Mensaje</th>
                                                    <th>Disponible</th>
                                                    <th>Resultado</th>

                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                                                    <td>
                                                        {{ item.name }}
                                                    </td>
                                                    <td>
                                                        {{ item.description }}
                                                    </td>
                                                    <td>
                                                        {{ item.requestDate }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            JSON.parse(item.commands).context == "E" ? "Emitido" : "Recibido"
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{ parseDocType(JSON.parse(item.commands).docType) }}
                                                    </td>
                                                    <td>
                                                        {{ JSON.parse(item.commands).dateInit }}
                                                    </td>
                                                    <td>
                                                        {{ JSON.parse(item.commands).dateEnd }}
                                                    </td>
                                                    <td>
                                                        {{ JSON.parse(item.commands).taxid }}
                                                    </td>
                                                    <td>
                                                        {{ item.message }}
                                                    </td>
                                                    <td>
                                                        {{ item.ready ? "Si" : "No" }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            item.ready
                                                            ? item.result
                                                                ? "Exitoso"
                                                                : "Fracasado"
                                                            : "Procesando"
                                                        }}
                                                    </td>
                                                    <td class="pa-0 md-0">
                                                        <v-tooltip left v-if="item.ready && item.result">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn @click="DescargarReporte(item.id)" icon
                                                                    color="#4aa832" v-on="on">
                                                                    <v-icon>mdi-download</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Descargar Reporte</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td class="pa-0 md-0">
                                                        <v-tooltip left v-if="item.ready">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn @click="ConfirmarEliminarReporte(item)" icon
                                                                    color="red" v-on="on">
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Eliminar Reporte</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-dialog>



                <v-dialog v-model="showDialogCrear" persistent max-width="800px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="showDialogCrear = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="11">
                                    <date-range-picker v-model="selectedDatesIn"></date-range-picker>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field v-model="rfcSearchIn" counter="0" label="RFC" class="my-1" maxlength="13">
                                    </v-text-field>
                                </v-col>
                                <v-col class="d-flex" cols="12" sm="6">
                                    <v-select :items="itemsSelect" v-model="tipoDocIn" label="Tipo de Comprobante" outlined>
                                    </v-select>
                                </v-col>
                                <v-col class="d-flex" cols="12" md="6">
                                    <v-select :items="itemsOrigen" v-model="contextoIn" label="Contexto" outlined>
                                    </v-select>
                                </v-col>
                                <v-col md="6" justify="center">
                                    <v-text-field v-model="nombreIn" counter="0" label="Nombre" class="my-1"
                                        maxlength="100">
                                    </v-text-field>
                                </v-col>
                                <v-col md="8" justify="center">
                                    <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1"
                                        maxlength="100">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider class="mt-12"></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar
                                Reporte
                            </v-btn>
                            <v-btn color="danger" text @click="showDialogCrear = false">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="showDialogEliminarReport = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Confirmación Eliminar Reporte:
                                {{ this.itemDesc }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <br />
                            <h3>Este reporte sera eliminado permanentemente</h3>
                            <h3>¿Está seguro de continuar con esta acción?</h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
                            <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h3>CFDIs con Inconsistencias entre Método y Forma de Pago
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                                    href="https://soporte.mysuitemex.com/portal/es/kb/articles/inconsistencias-metodo-de-pago-vs-forma-de-pago"
                                    target="_blank">
                                    <v-icon>mdi-help-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Manual</span>
                        </v-tooltip>
                    </h3>
                </v-col>
                <v-col>
                    <v-card class="paddingCard">
                        <v-row>
                            <v-col cols="4" class="px-2 mx-md-10">
                                <date-range-picker v-model="selectedDates"></date-range-picker>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-text-field v-model="rfcSearch" @change="cambio = true" counter="0" :label="labelRfc"
                                    maxlength="13" class="min-width-control-xl"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-select class="min-width-control-xl" :items="itemsSelect" v-model="tipoDoc"
                                    label="Tipo de Comprobante" outlined>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="context"
                                    @change="cambioOrigen" label="Contexto" outlined>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                                <v-btn color="primary" white large v-on:click="getReport"
                                    :disabled="!showSearchButton">Mostrar Listado</v-btn>

                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                                        <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">Reportes
                                            Bajo Demanda
                                        </v-btn>
                                    </template>
                                    <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="items.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de {{ totalDocumentos }}
                        </v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>

                        <v-tooltip top class="d-none d-sm-flex">
                            <template v-slot:activator="{ on }">
                                <v-icon class="d-none d-sm-flex" @click="getReporteCSV" v-on="on"
                                    :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
                            </template>
                            <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
                        </v-tooltip>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>



                        <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :frozenColumns="2" :initialized="initGrid" :allowResizing="'None'">

                            <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*"
                                :isReadOnly="true" cssClass="main-column" />
                            <wj-flex-grid-column :header="'Forma de Pago'" width="2*" :isReadOnly="true"
                                cssClass="main-column">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getFormaPago(cell.item.forma_pago) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor_rfc'"
                                width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'emisor_rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'receptor_nombre'"
                                width="4*" :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'emisor_nombre'" width="4*"
                                :isReadOnly="true" />


                            <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                                :isReadOnly="true" />


                            <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>

                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="ConsultarEstadoDoc(cell.item)" icon color="orange" v-on="on">
                                                <v-icon>mdi-information-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Consultar Estado del Documento</span>
                                    </v-tooltip>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>



                    </v-card>
                    <br />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
import Dialogo from '../Componentes/DialogoBuscar.vue';

export default {
    name: "PaymentMethodVsForm",
    components: {
        DateRangePicker,
        Dialogo
    },
    data: () => ({
        itemFind: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
            retencion_isr: 0,
            retencion_iva: 0,
            retencion_ieps: 0,
            traslado_iva: 0,
            traslado_ieps: 0,
        },
        selectedDates: [],
        selectedDatesIn: [],
        selectedRows: [],
        date: "",
        date2: "",
        menu: false,
        menu2: false,
        showDialog: false,
        showDialogStatus: false,
        goPage: 1,
        selectedStatus: {
            estado: "",
            cancelable: "",
            estado_cancelacion: "",
        },
        cambio: false,
        folioDoc: "",
        modal: false,




        search: "",
        offest: 0,
        limit: 50,
        pageSize: "50",
        noPagina: 1,
        totalPaginas: 1,
        doctosPagina: 0,
        totalDocumentos: 0,
        paginations: ["25", "50", "100", "150", "200"],
        items: [],
        tipoDoc: "Seleccionar Todos",
        itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso"],
        landscape: false,
        title: "",
        csvData: [],
        csvDataT: [],
        currentOrder: "DESC",
        currentOrderColumn: "nfecha_emision",
        rfcRClass: false,
        serieClass: false,
        fechaEClass: true,
        rfcEClass: false,
        folioClass: false,
        monedaClass: false,
        tipo_comprobanteClass: false,
        metodo_pagoClass: false,
        forma_pagoClass: false,
        itemSelected: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0
        },
        rfcSearch: "",
        context: "Emitidos",
        showDialogEliminarReport: false,
        showDialogReports: false,
        showDialogCrear: false,
        errorMessageIn: "",
        okMessageIn: "",
        alert_errorIn: false,
        alert_okIn: false,
        itemsGrandes: [],
        rfcSearchIn: "",
        descripcionIn: "",
        nombreIn: "",
        itemDesc: "",
        tipoDocIn: "Seleccionar Todos",
        contextoIn: "Emitidos"
    }),

    methods: {
        initGrid: function (grid) {

            for (let i = 0; i < grid.columns.length; i++) {
                grid.autoSizeColumn(i);
                grid.columns[i].width += 20;
            }


            grid.columns[grid.columns.length - 1].width = 200;


        },
        retrieveReporte(ruta) {
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(ruta, "_blank");
            } else {
                helpers.openNewBackgroundTab(ruta);
            }
            setTimeout(() => {
                this.$store.commit("setOverlay", false);
            }, 1000);
        },
        DescargarReporte(itemId) {
            var jsonData = {
                id: itemId,
                token: this.$store.state.token
            };

            service
                .api()
                .RetrieveOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.retrieveReporte(response.payload);
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        EliminarReporte() {
            var jsonData = {
                id: this.eliminarId,
                token: this.$store.state.token,
                reportType: 'REPORT-MISMATCH-FORMA-METODO'
            };
            this.showDialogEliminarReport = false;
            service
                .api()
                .DeleteOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.alert_okIn = true;
                        this.okMessageIn = "Reporte Eliminado Correctamente";
                        setTimeout(() => {
                            this.alert_okIn = false;
                            this.okMessageIn = "";
                        }, 10000);
                        if (this.itemsGrandes.length == 1)
                            this.itemsGrandes = [];
                        else
                            this.ObtenerReportes();
                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                });
        },
        requestItems() {
            this.showDialogCrear = false;
            this.showDialogReports = false;

            var jsonData = {
                reportType: 'REPORT-MISMATCH-FORMA-METODO',
                name: this.nombreIn,
                description: this.descripcionIn,
                commands: JSON.stringify({ dateInit: this.selectedDatesIn[0], dateEnd: this.selectedDatesIn[1], context: this.contextoIn.substr(0, 1), docType: this.getTipoDocIn(), rfc: this.rfcSearch }),
                token: this.$store.state.token,
            };

            service
                .api()
                .RequestOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.alert_okIn = true;
                        this.okMessageIn = "Reporte Solicitado Correctamente";
                        setTimeout(() => {
                            this.alert_okIn = false;
                            this.okMessageIn = "";
                        }, 10000);

                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                    this.descripcionIn = "";
                });

            this.nombreIn = "";
            this.descripcionIn = "";
            this.selectedDatesIn[0] = "";
            this.selectedDatesIn[1] = "";
            this.contextoIn = "Emitidos";
            this.tipoDocIn = "Seleccionar Todos",
                this.rfcSearch = "";
            this.showDialogReports = true;


        },
        ObtenerReportes() {
            this.showDialogReports = true;
            var jsonData = {
                token: this.$store.state.token,
                reportType: 'REPORT-MISMATCH-FORMA-METODO'
            };

            service
                .api()
                .RetrieveOnDemandReports(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.itemsGrandes = JSON.parse(response.payload);
                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;

                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                });
        },
        ConfirmarEliminarReporte(item) {
            this.eliminarId = item.id;
            this.itemDesc = item.description;
            this.showDialogEliminarReport = true;
        },

        cambioOrigen() {

            this.items = [];
        },

        rowClicked(index) {
            this.selectedRows = [];
            this.toggleSelection(index);
        },
        toggleSelection(keyID) {
            if (this.selectedRows.includes(keyID)) {
                this.selectedRows = this.selectedRows.filter(
                    (selectedKeyID) => selectedKeyID !== keyID
                );
            } else {
                this.selectedRows.push(keyID);
            }
        },
        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contexto,
                uuid: item.id,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (item.tipo === "R")
                par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
            else par = item.tipo + "|" + item.pac + "|";
            let parameters = par + "|" + item.id + "|" + item.fecha_emision;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");


            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 3000);
        },

        openDialog() {
            this.$refs.dialog.showDialogGuid = true;
        },
        verMasDatos(item) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: item.id,
            };
            service
                .api()
                .SearchByGUID(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemFind = payload;
                        this.openDialog();
                    } else {

                        this.alert_errorR = true;
                        this.errorMessageR = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorR = false;
                            this.errorMessageR = "";
                        }, 10000);
                    }
                });

        },
        exportExcel() {
            var headers = {
                UUID: "Folio Fiscal",
                RfcE: "RFC Emisor",
                NombreE: "Razon Social Emisor",
                RfcR: "RFC Receptor",
                NombreR: "Razon Social Receptor",
                CRegimen: "Código Regimen",
                NRegimen: "Regimen",
                ResidenciaR: "Residencia Receptor",
                CUsoCfdiR: "Código Uso CFDI",
                NUsoCfdiR: "Uso CFDI",
                Tipo: "Contexto",
                TipoComprobante: "Tipo Comprobante",
                FechaCancelacion: "Fecha Cancelación",
                FechaEmision: "Fecha Emisión",
                FechaTimbre: "Fecha Timbre",
                Serie: "Serie",
                Folio: "Folio",
                LugarExpedicion: "Lugar de Expedicion",
                MetodoPago: "Método Pago",
                CFormaPago: "Código Forma de Pago",
                NFormaPago: "Forma de Pago",
                Moneda: "Moneda",
                TipoCambio: "Tipo Cambio",
                SubTotal: "Subtotal",
                Descuento: "Descuento",
                Total: "Total",
                PAC: "PAC",
            };
            var itemsFormatted = [];
            this.csvData.forEach((item) => {
                itemsFormatted.push({
                    UUID: helpers.ConvertToEmpty(item.id),
                    RfcE: helpers.ConvertToEmpty(item.emisor_rfc),
                    NombreE: helpers.ConvertToEmpty(item.emisor_nombre).replace(/,/g, " "),
                    RfcR: helpers.ConvertToEmpty(item.receptor_rfc),
                    NombreR: helpers.ConvertToEmpty(item.receptor_nombre),
                    CRegimen: helpers.ConvertToEmpty(item.regimen),
                    NRegimen: helpers.RegimenFiscal(helpers.ConvertToEmpty(item.regimen)).replace(/,/g, " "),
                    ResidenciaR: helpers.ConvertToEmpty(item.residencia),
                    CUsoCfdiR: helpers.ConvertToEmpty(item.usocfdi),
                    NUsoCfdiR: helpers.UsoCFDI(helpers.ConvertToEmpty(item.usocfdi)).replace(/,/g, " "),
                    Tipo: helpers.ConvertToEmpty(item.tipo),
                    TipoComprobante: helpers.ConvertToEmpty(item.tipo_comprobante),
                    FechaCancelacion: helpers.ConvertToEmpty(item.fecha_cancelacion),
                    FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
                    FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
                    Serie: helpers.ConvertToEmpty(item.serie),
                    Folio: helpers.ConvertToEmpty(item.folio),
                    LugarExpedicion: helpers.ConvertToEmpty(item.lugar_expedicion),
                    MetodoPago: helpers.ConvertToEmpty(item.metodo_pago),
                    CFormaPago: helpers.ConvertToEmpty(item.forma_pago),
                    NFormaPago: helpers.FormaPago(helpers.ConvertToEmpty(item.forma_pago)).replace(/,/g, " "),
                    Moneda: helpers.ConvertToEmpty(item.moneda),
                    TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio),
                    SubTotal: helpers.ConvertToEmpty(item.subtotal),
                    Descuento: helpers.ConvertToEmpty(item.descuento),
                    Total: helpers.ConvertToEmpty(item.total),
                    PAC: helpers.ConvertToEmpty(item.pac),
                });
            });

            this.csvDataT = itemsFormatted;
            var fileTitle =
                "Listado_Inconsistencias_MetodoPago_FormaPago" + helpers.dateNowCSV().replace(/-/g, "_");
            helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
        },
        start() {
            this.offest = 0;
            this.goPage = this.noPagina = 1;
            this.limit = this.pageSize;
            this.getReporteItems();
        },
        previous() {
            if (this.noPagina === 1) return;

            this.limit = this.pageSize;
            this.noPagina--;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;
            this.getReporteItems();
        },
        next() {
            if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
                return;

            this.limit = this.pageSize;
            if (this.noPagina + 1 === this.totalPaginas) {
                let res = this.totalDocumentos % this.pageSize;
                if (res !== 0) this.limit = res;
            }
            this.noPagina++;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;

            this.getReporteItems();
        },
        end() {
            this.limit = this.pageSize;
            let res = this.totalDocumentos % this.pageSize;
            if (res !== 0) this.limit = res;

            this.goPage = this.noPagina = this.totalPaginas;
            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
        },
        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                this.getReporteItems();
            else this.noPagina = this.goPage = actualPage;

        },
        changePagination() {
            this.getReport();
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        currencyFormat6(value) {
            return helpers.formatMoney(value, 6);
        },
        handleDate() {
            this.limit = this.pageSize;
            this.offest = 0;
            this.noPagina = 1;
            this.$refs.menu.save(this.date);
            this.getReporteItems();
        },
        getReport() {
            this.goPage = 1;
            this.offest = 0;
            this.noPagina = 1;
            this.limit = this.pageSize;
            this.$store.commit("setAlertError", false);
            this.errorMessage = "";
            this.getTotalCount();
            if (this.cambio) {
                this.cambio = false;
            }
        },
        getReporteItems() {
            this.items = [];
            this.selectedRows = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
            };
            service
                .api()
                .MismatchPaymentMethodsList(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.items = payload;

                        this.doctosPagina = this.items.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCount() {
            this.items = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                context: this.contexto,
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
            };

            service
                .api()
                .MismatchPaymentMethodsCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItems();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getReporteCSV() {
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto,
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
            };

            service
                .api()
                .MismatchPaymentMethodsCSV(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.csvData = JSON.parse(response.payload);
                        this.exportExcel();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
                    }
                });
        },
        ConsultarEstadoDoc(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: item.tipo,
                uuid: item.id,
                issuedDate: item.fecha_emision,
                issuer: item.emisor_rfc,
                receiver: item.receptor_rfc,
                total: item.total,
            };

            service
                .api()
                .Consult(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.selectedStatus = JSON.parse(response.payload);
                        this.itemSelected = item;
                        this.showDialogStatus = true;
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },

        esOrdenamiento(columna) {
            return this.currentOrderColumn === columna;
        },
        cerrarStatus() {
            this.showDialogStatus = false;
            if (this.selectedStatus.estado == "Cancelado") this.getReport();
        },
        sortColumn(columnName) {
            if (this.currentOrderColumn === columnName) {
                if (this.currentOrder === "DESC") {
                    this.currentOrder = "ASC";
                } else {
                    this.currentOrder = "DESC";
                }
            }
            this.currentOrderColumn = columnName;
            this.getReporteItems();

            this.rfcRClass = false;
            this.serieClass = false;
            this.fechaEClass = false;
            this.rfcEClass = false;
            this.folioClass = false;
            this.monedaClass = false;
            this.tipo_comprobanteClass = false;
            this.metodo_pagoClass = false;
            this.forma_pagoClass = false;

            switch (columnName) {
                case "receptor.rfc":
                    this.rfcRClass = true;
                    break;
                case "serie":
                    this.serieClass = true;
                    break;
                case "nfecha_emision":
                    this.fechaEClass = true;
                    break;
                case "emisor.rfc":
                    this.rfcEClass = true;
                    break;
                case "folio":
                    this.folioClass = true;
                    break;
                case "moneda":
                    this.monedaClass = true;
                    break;
                case "tipo_comprobante":
                    this.tipo_comprobanteClass = true;
                    break;
                case "metodo_pago":
                    this.metodo_pagoClass = true;
                    break;
                case "forma_pago":
                    this.forma_pagoClass = true;
                    break;
            }
        },

        getTipoDoc() {
            let firstLetter = this.tipoDoc.substr(0, 1);
            return firstLetter === "S" ? "" : firstLetter;
        },
        getTipoDocIn() {
            let firstLetter = this.tipoDocIn.substr(0, 1);
            return firstLetter === "S" ? "" : firstLetter;
        },
        getTipoComprobante(tipoDoc) {
            let tipo = "";

            switch (tipoDoc) {
                case "I":
                    tipo = "Ingreso";
                    break;
                case "E":
                    tipo = "Egreso";
                    break;
                case "T":
                    tipo = "Traslado";
                    break;
                case "N":
                    tipo = "Nomina";
                    break;
            }
            return tipo;
        },
        parseDocType(tipoDoc) {

            return helpers.parseDoctype(tipoDoc);

        },

        getFormaPago(formaP) {
            return helpers.FormaPago(formaP);
        }
    },
    computed: {
        hasNext() {
            return this.noPagina !== this.totalPaginas;
        },
        hasPrevious() {
            return this.noPagina !== 1;
        },
        esEmitido() {
            return this.contexto === "E";
        },
        esRecibido() {
            return this.contexto === "R";
        },
        showSearchButton() {
            return this.selectedDates.length > 1;
        },
        labelRfc() {
            if (this.contexto === "E") return "RFC Receptor";
            else return "RFC Emisor";
        },
        contexto() {
            return this.context.substr(0, 1);
        },
        helpers() {
            return helpers;
        },
        fechaInicial() {
            return helpers.getFechaLimite(false);
        },
        allowIssued() {
            return this.$store.state.allowIssued === true;
        },
        allowReceived() {
            return this.$store.state.allowReceived === true;
        },
        itemsOrigen() {
            let elements = [];
            if (this.allowIssued)
                elements.push("Emitidos");
            if (this.allowReceived)
                elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        },
        showCreateButton() {
            //return this.selectedDatesIn.length > 1  && this.descripcionIn.length > 0;
            return this.selectedDatesIn.length > 1 && this.descripcionIn.length > 0;
        },
    },
};
</script>

<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoGuia {
    width: 50px;
}


.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {

    font-weight: bold;
    font-size: 20px;
}

.paddingCard {
    padding-right: 25px;
    padding-left: 15px;
}


.min-width-control {
    min-width: 160px;
}

.min-width-control-xl {
    min-width: 200px;
}

.wj-flexgrid {
    max-height: 600px;
    margin: 10px 0;
}

.main-column,
.main-column.wj-alt {
    font-weight: bolder;
    color: blue;
    background: lightskyblue;
}
</style>

